<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>
  <div v-else>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Question Bank</p>
        <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg" />
          </v-hover>
        </v-badge>

        <div>
          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <v-container fluid class="px-0 py-0 qpad">
      <v-dialog v-model="uploadingDialog" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon v-if="uploadingMsg != 'File Uploading...'" color="success"
                size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">{{ uploadingMsg }}</p>
              <v-progress-circular :size="50" color="blue" indeterminate
                v-if="uploadingMsg == 'File Uploading...'"></v-progress-circular>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="uploadingDialog = false"
                v-if="uploadingMsg != 'File Uploading...'">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-row v-if="createQuestionDialog" class="px-0 abc">
        <v-col cols="12" sm="12" md="12">
          <div class="background pl-8 pr-4" elevation="0">
            <div>
              <v-card-title class="px-0">
                <div elevation="0" class="background w-100 d-flex justify-space-between">
                  <v-toolbar-title class="text-h5 px-0">{{ formbtn() }} Question</v-toolbar-title>
                  <v-row align="center" justify="end">
                    <v-btn class="btn outline-btn font-weight-medium mx-3 elevation-0" rounded
                      @click="createQuestionDialog = false">Cancel</v-btn>
                    <template v-if="$store.state.role.questionBank.admin">
                      <v-progress-circular v-if="isCreatingQuestion" indeterminate
                        color="blue"></v-progress-circular>

                      <v-btn v-else rounded class="mx-3 btn primary-btn" @click="
                        !formbtnBool ? createQuestion() : updateInput(editId)
                        ">
                        <v-icon></v-icon>{{ formbtn() }}
                      </v-btn>
                    </template>
                  </v-row>
                </div>
              </v-card-title>
            </div>
            <v-card id="myScroll" style="height: calc(100vh - 166px); overflow-x: hidden;" >
              <v-form ref="form" lazy-validation>
                <v-card-text>
                  <v-card class="ma-4 pa-4" elevation="0">
                    <!-- Select Question Type   -->
                    <v-row>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete v-model="questionType" label="Question Type*" :items="questionTypeList" outlined
                          class="rounded-xl" :rules="[(v) => !!v || 'Please select question type']" required attach>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <v-row align="center" class="d-flex flex-row justify-space-between">
                          <v-col class="pt-0 pb-0">
                            <div class="text-body-1 my-2">Question*</div>
                          </v-col>
                          <v-col class="pa-0">
                            <v-btn class="btn outline-btn float-right my-2"
                              v-if="questionAssetUrl == 'NA'"><v-icon>mdi-attachment</v-icon>
                              <span>Attach Audio/Video/Image</span>
                              <input type="file" name="image" @change="onChange" accept="image/*, audio/*, video/*" />
                            </v-btn>
                            <span>
                              <v-chip text class="float-right my-2" v-if="mainImage && questionAssetUrl !== 'NA'"
                                @click:close="closeChip" @click="openImagePreviewDialog" close>View Attachment</v-chip>
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <ckeditor :config="editorConfig" v-model="questionName" class="m-ckeditor"
                          :rules="[(v) => !!v || 'Question is required']" required @ready="onEditorReady">
                        </ckeditor>
                      </v-col>
                    </v-row>
                    <!-- Single Select Answers -->
                    <v-row v-if="questionType == 'SINGLE_CHOICE'">
                      <v-col>
                        <div class="text-body-1 mb-2">
                          Options*
                          <span class="font-italic grey--text">(Guidelines for image in options: 1. File type -
                            .jpeg/.jpg, .png, .bmp 2. File size - 5MB)
                          </span>
                        </div>
                        <v-radio-group v-model="singleSelectCorrectAnswer" :rules="[(v) => !!v || 'Please select one']">
                          <v-list-item :class="singleSelectCorrectAnswer == option.optionKey
                            ? 'lightGreen rounded-xl my-2'
                            : 'grey lighten-4 rounded-xl my-2'
                            " min-height="72" v-for="(option, index) in options" :key="index">
                            <v-list-item-avatar>
                              <v-radio :value="option.optionKey"></v-radio>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-subtitle v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')">
                                <span class="d-flex align-start"><img @loadstart="resolveQuestionOptionUrl"
                                    :src="option.optionValue" alt="" width="auto" height="150" class="img" />
                                  <v-btn icon class="ml-1"><v-icon
                                      @click="restorePrevValue(index)">mdi-close</v-icon></v-btn></span>
                              </v-list-item-subtitle>
                              <v-list-item-title v-else class="align-center">
                                <div v-html="option.optionValue"></div>
                              </v-list-item-title>

                              <!-- <v-list-item-title >
                            <ckeditor                     
                              :config="editorConfig"
                              v-model="option.optionValue"
                              class="m-ckeditor"
                              :rules="[(v) => !!v || 'Question is required']"
                              required
                            >
                            </ckeditor>
                          </v-list-item-title> -->
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-row align="center" class="overflow-hidden">
                                <v-btn v-if="!option.optionValue.startsWith('https://') || !option.optionValue.startsWith('http://') || !option.optionValue.startsWith('blob:')"  icon @click="editOption(index)">
                                  <v-icon large class="pa-2">mdi-format-textbox
                                  </v-icon>
                                </v-btn>
                                <v-btn text color="blue" x-large @click="editedOptionIndex = index"
                                  v-on:click="uploadingForOption = true">Upload Image
                                  <input type="file" name="image" accept="image/*" @change="onChange"
                                    v-on:click="uploadingForOption = true" />
                                </v-btn>
                                <v-btn icon @click="removeOption(index)">
                                  <v-icon>mdi-delete </v-icon>
                                </v-btn>
                              </v-row>
                            </v-list-item-action>
                          </v-list-item>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <!-- Multi Select Answers -->
                    <v-row v-else-if="questionType == 'MULTIPLE_CHOICE'">
                      <v-col cols="12" sm="12" md="12" class="overflow-hidden">
                        <div class="text-body-1 mb-2">
                          Options*
                          <span class="font-italic grey--text">(Guidelines for image in options: 1. File type -
                            .jpeg/.jpg, .png, .bmp 2. File size - 5MB)
                          </span>
                        </div>

                        <v-list-item :class="selectedAnswersForMultitpleTypeQuestions.includes(
                          option.optionKey
                        )
                          ? 'lightGreen rounded-xl my-2'
                          : 'grey lighten-4 rounded-xl my-2'
                          " min-height="72" v-for="(option, index) in options" :key="index">
                          <v-list-item-avatar>
                            <v-checkbox v-model="selectedAnswersForMultitpleTypeQuestions"
                              :value="option.optionKey"></v-checkbox>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-subtitle v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')">
                              <span class="d-flex align-start"><img @loadstart="resolveQuestionOptionUrl"
                                  :src="option.optionValue" alt="" width="auto" height="150" class="img" />
                                <v-btn icon class="ml-1"><v-icon
                                    @click="restorePrevValue(index)">mdi-close</v-icon></v-btn></span>
                            </v-list-item-subtitle>

                            <v-list-item-title v-else class="align-center">
                              <div v-html="option.optionValue"></div>
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-row align="center">
                              <v-btn  v-if="!option.optionValue.startsWith('https://') || !option.optionValue.startsWith('http://') || !option.optionValue.startsWith('blob:')"  icon @click="editOption(index)">
                                <v-icon large class="pa-2">mdi-format-textbox
                                </v-icon>
                              </v-btn>
                              <v-btn text color="blue" x-large @click="editedOptionIndex = index"
                                v-on:click="uploadingForOption = true">Upload Image
                                <input type="file" name="image" @change="onChange" />
                              </v-btn>
                              <v-btn icon @click="removeOption(index)">
                                <v-icon>mdi-delete </v-icon>
                              </v-btn>
                            </v-row>
                          </v-list-item-action>
                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-row v-if="questionType == 'SINGLE_CHOICE' ||
                      questionType == 'MULTIPLE_CHOICE'
                    " justify="start" align="center" class="px-2 blue--text" @click="addOption">
                      <v-icon large>mdi-plus-circle-outline</v-icon>
                      <v-btn class="pl-0" text x-large color="blue">Add Options</v-btn>
                    </v-row>
                    <v-row v-if="questionType == 'FILL_IN_THE_BLANKS'">
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-textarea :rules="[
                          (v) => !!v || 'Correct Answer field is required',
                        ]" required outlined class="rounded-xl" label="Correct Answer*" v-model="correctAnswer">
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <!-- True False -->
                    <v-row v-if="questionType == 'TRUE_FALSE'">
                      <v-col>
                        <div class="text-body-1 mb-2">Options*</div>
                        <v-radio-group v-model="trueFalseCorrectAnswer" :rules="[(v) => !!v || 'Please select one']">
                          <v-list-item :class="trueFalseCorrectAnswer == option.optionKey
                            ? 'lightGreen rounded-xl my-2'
                            : 'grey lighten-4  rounded-xl my-2'
                            " min-height="72" v-for="(option, index) in [
                              { optionKey: 'A', optionValue: 'TRUE' },
                              { optionKey: 'B', optionValue: 'FALSE' },
                            ]" :key="index">
                            <v-list-item-avatar>
                              <v-radio :value="option.optionKey"></v-radio>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                option.optionValue
                              }}</v-list-item-title>
                            </v-list-item-content>
                            <!-- <v-list-item-action>
                          <v-row align="center">
                            <v-btn icon>
                              <v-icon>mdi-dots-vertical </v-icon>
                            </v-btn>
                          </v-row>
                        </v-list-item-action> -->
                          </v-list-item>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <!-- Match the Following -->

                    <v-row v-if="questionType == 'MATCH_THE_FOLLOWING'">
                      <v-col>
                        <div class="text-body-1 mb-2">Options*</div>
                        <v-list-item class="secondary rounded-xl my-2" min-height="72"
                          v-for="(option, index) in options" :key="index">
                          <v-list-item-content>
                            <v-list-item-title></v-list-item-title>
                            <v-list-item-subtitle v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')">
                              <span class="d-flex align-start"><img @loadstart="resolveQuestionOptionUrl"
                                  :src="option.optionValue" alt="" width="auto" height="150" class="img" />
                                <v-btn icon class="ml-1"><v-icon
                                    @click="restorePrevValue(index)">mdi-close</v-icon></v-btn></span>
                            </v-list-item-subtitle>
                            <v-list-item-title v-else class="align-center">
                              <div v-html="option.optionValue"></div>
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-row align="center">
                              <v-btn v-if="!option.optionValue.startsWith('https://') || !option.optionValue.startsWith('http://') || !option.optionValue.startsWith('blob:')"  icon @click="editOption(index)">
                                <v-icon large class="pa-2">mdi-format-textbox
                                </v-icon>
                              </v-btn>
                              <v-btn text color="blue" x-large @click="editedOptionIndex = index"
                                v-on:click="uploadingForOption = true">Upload Image
                                <input type="file" name="image" @change="onChange" />
                              </v-btn>
                              <v-btn icon>
                                <v-icon>mdi-dots-vertical </v-icon>
                              </v-btn>
                            </v-row>
                          </v-list-item-action>
                        </v-list-item>
                      </v-col>
                      <v-col>
                        <div class="text-body-1 mb-2">Correct Answer*</div>
                        <v-list-item class="secondary rounded-xl my-2" min-height="72"
                          v-for="(answer, index) in mtfAnswers" :key="index">
                          <v-list-item-content>
                            <v-list-item-title class="align-center">
                              <div v-html="answer.value"></div>
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-row align="center">
                              <v-btn v-if="!option.optionValue.startsWith('https://') || !option.optionValue.startsWith('http://') || !option.optionValue.startsWith('blob:')"  icon @click="editOption(index)">
                                <v-icon large class="pa-2">mdi-format-textbox
                                </v-icon>
                              </v-btn>
                              <v-btn text color="blue" x-large>Upload Image</v-btn>
                              <v-btn icon>
                                <v-icon>mdi-dots-vertical </v-icon>
                              </v-btn>
                            </v-row>
                          </v-list-item-action>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <!-- Fill in the blanks -->
                    <v-row v-if="questionType == 'Fill in the blanks'">
                      <v-col class="py-0">
                        <div class="text-body-1 my-2">Answer*</div>
                        <v-textarea outlined class="rounded-xl" label="Enter Answer">
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-textarea :rules="[
                          (v) =>
                            !!v || 'Answer Explanation field is required',
                        ]" required outlined class="rounded-xl" label="Answer Explaination*"
                          v-model="answerExplanation">
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-textarea outlined class="rounded-xl textarea" label="Answer Hint*"
                          :rules="[(v) => !!v || 'Answer Hint is required']" required v-model="hint">
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="6">
                        <div class="text-body-1 my-2"></div>
                        <v-text-field type="number" outlined class="rounded-xl" label="Correct Answer Score*" v-model="correctAnswerScore">
                        </v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="6">
                        <div class="text-body-1 my-2"></div>
                        <v-row>
                          <!-- <v-col cols="2">
                            <v-text-field
                              outlined
                              class="rounded-xl"
                              value="00"
                              label="Mins"
                              type="number"
                            >
                            </v-text-field>
                          </v-col> -->
                          <v-col cols="12">
                            <v-text-field outlined class="rounded-xl" value="00"
                              label="Estimated Time To Answer The Question (Secs)*" type="number" v-model="estimatedTime">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card class="ma-4 pa-4" elevation="0">
                    <v-row>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete
                          v-model="product"
                          label="Product*" 
                          :items="productOptions"
                          outlined
                          class="rounded-xl"
                          required
                          :rules="[(v) => !!v || 'Product is required']"
                          attach
                        ></v-autocomplete>
                      </v-col>
                      
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-text-field
                          v-model="questionid"
                          label="Question ID*"
                          outlined
                          class="rounded-xl"
                          required
                          :rules="questionIdRules"
                          :error-messages="questionIdError"
                          @input="validateQuestionId"
                        ></v-text-field>
                      </v-col>

                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete
                          v-model="languageOfQuestion"
                          label="Language*"
                          :items="languageOptions" 
                          outlined
                          class="rounded-xl"
                          required
                          :rules="[(v) => !!v || 'Language is required']"
                          attach
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete
                          label="Group Name*"
                          :items="groupNames"
                          outlined 
                          class="rounded-xl"
                          required
                          v-model="groupName"
                          :rules="[(v) => !!v || 'Group Name is required']"
                          attach
                        ></v-autocomplete>
                      </v-col>
                      
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete
                          label="Case Sensitive*"
                          :items="['Yes', 'No']"
                          outlined
                          class="rounded-xl"
                          required
                          v-model="isCaseSensitive"
                          :rules="[(v) => !!v || 'Case Sensitive is required']"
                          attach
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete
                          v-model="selectedHrisRoles"
                          label="HRIS Roles*"
                          :items="hrisUniqueRole"
                          outlined
                          class="rounded-xl"
                          :rules="[(v) => v.length > 0 || 'At least one HRIS role is required']"
                          attach
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Teaching Level*" :items="levels" outlined class="rounded-xl" required
                          :rules="[
                            (v) => !!v || 'Teaching Level field is required',
                          ]" v-model="level" item-text="name" item-value="name" attach>
                        </v-autocomplete>
                      </v-col>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Grade" :items="grades" outlined class="rounded-xl" required
                          v-model="grade" item-text="name" item-value="name" attach>
                        </v-autocomplete>
                      </v-col>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Difficulty Level*" :items="difficultyLevels" outlined class="rounded-xl"
                          required :rules="[
                            (v) => !!v || 'Difficulty Level is required',
                          ]" v-model="difficultyLevel" attach>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Complexity Level*" :items="complexityLevels" outlined class="rounded-xl"
                          v-model="complexityLevel" required :rules="[
                            (v) => !!v || 'Complexity Level is required',
                          ]" attach>
                        </v-autocomplete>
                      </v-col>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Knowledge Level*" :items="knowledgeLevels" outlined class="rounded-xl"
                          v-model="knowledgeLevel" attach>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Bloom's Taxonomy Level*" :items="bloomsTaxonomyLevel" outlined
                          class="rounded-xl" v-model="bloomsTaxonomy" required :rules="[
                            (v) => !!v || ' Blooms Taxonomy Level is required',
                          ]" attach>
                        </v-autocomplete>
                      </v-col>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Related Skill*" :items="skills" v-model="skill" outlined item-text="name"
                          item-value="name" class="rounded-xl" :rules="[
                            (v) => !!v || 'Related Skill field is required',
                          ]" required @change="checkSubjects(skill)" attach>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row v-if="skill == 'Core Skills'">
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Subject" clearable deletable-chips multiple :items="subjects" outlined class="rounded-xl" v-model="subject"
                          item-text="name" item-value="name" :search-input.sync="searchSubjects"  attach>
                        </v-autocomplete>
                      </v-col>
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Proficiency Level*" :items="['A1', 'A2', 'B1', 'B2', 'C1', 'C2']"
                          outlined class="rounded-xl" v-model="proficiencyLevel" :rules="[
                            (v) => !!v || 'Proficiency Level is required',
                          ]" required attach>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete v-model="selectedLOs" clearable deletable-chips label="Select or Search LO's"
                          outlined class="rounded-xl" height="50px" chips :search-input.sync="searchLOs" :items="LOData"
                          multiple item-text="name" item-value="io_code" attach>
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field outlined class="rounded-xl" value="00" label="Topic" type="text" v-model="topic">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col>
                        <v-text-field outlined class="rounded-xl" value="00" label="Strand" type="text"
                          v-model="strand">
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field outlined class="rounded-xl" value="00" label="Sub Strand" type="text"
                          v-model="subStrand">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                    
                        <div outlined class="rounded-xl outlined tag-compo mb-8">
                          <v-chip v-for="(tag, index) in tagsList" :key="index" :close="true"
                            @click:close="removeTag(index)" class="ma-2">
                            {{ tag }}
                          </v-chip>
                    
                          <!-- Adjust the input field width for better visibility -->
                          <input 
                            class="ma-4 tag-input"
                            placeholder="Tags (Separate tags with commas or Enter)"
                            @keydown.enter="addTag"
                            v-model="tag"
                            style="width: 100%; padding: 8px;"  
                          />
                        </div>
                      </v-col>
                    </v-row>
                    
                    <v-row>
                      <!-- <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Type of audience" :items="typeOfAudienceOptions" outlined class="rounded-xl" required
                          v-model="typeOfAudience" item-text="name" item-value="name" attach>
                        </v-autocomplete>
                      </v-col> -->
                      <v-col class="py-0">
                        <div class="text-body-1 my-2"></div>
                        <v-autocomplete label="Authored By" :items="authoredByOptions" outlined class="rounded-xl" required
                          v-model="authoredBy" item-text="name" item-value="value" attach>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    
                  </v-card>
                </v-card-text>
              </v-form>
            </v-card>
          </div>
        </v-col>
      </v-row>

      <div class="pr-4 pl-8" v-else>
        <v-row class="mt-6 justify-space-between">
          <v-col cols="3">
            <div class="text-h6">Question Bank</div>
          </v-col>
          <v-col cols="9" class="mt-0 mb-6">
            <v-row justify="end">
              <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" hide-details v-model="search"
                class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              <v-btn @click="filterDialog = true" depressed rounded outlined
                class="btn outline-btn mx-3 pl-4 pr-6"><v-icon class="pr-2">mdi-tune</v-icon>
                <span v-if="filterCount > 0">Filter ({{ filterCount }})</span>
                <span v-else>Filter</span></v-btn>

              <v-btn v-if="$store.state.role.questionBank.admin" depressed rounded outlined
                class="btn mx-3 pl-4 pr-6" @click="deleteDialog = true"
                :class="selectedTableItems.length == 0 ? 'outline-disabled-btn' : 'outline-btn'"
                :disabled="selectedTableItems.length == 0"><v-icon
                  class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
              <v-btn @click="downloadQuestions" :loading="loadingQuestionsToDownload" depressed outlined rounded class="btn outline-btn mx-3"><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="$store.state.role.questionBank.admin" class="btn primary-btn mx-3 pl-4 pr-6" @click="clearValue"
                    rounded depressed v-bind="attrs" v-on="on">
                    <v-icon class="pr-2">mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="createQuestionDialog = true">
                    <v-list-item-title>
                      <v-icon>mdi-book-open-variant </v-icon> CREATE NEW
                      QUESTION
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>
                      <v-icon>mdi-file-document-multiple </v-icon>
                      <input type="file" @change="handleFileUpload" />

                      CREATE NEW QUESTIONS IN BULK
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title @click="downloadFile" class="cursor">
                      <v-icon>mdi-book-open-variant </v-icon>
                      DOWNLOAD SAMPLE FILE
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <!-- Notification Dialog -->
              <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
                <Notification @close-dialog="notificationDialog = false" />
              </v-dialog>

              <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
                <v-card fluid>
                  <v-container fluid class="pa-0">
                    <v-card-text class="text-center">
                      <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>
                      <p class="text-h5 pt-4 pb-0">Delete Question</p>
                      <p class="text-disabled grey--text text-subtitle-1 mb-4" color="rgba(0, 0, 0, 0.6)" disabled>
                        This action will permanently delete the item . This
                        cannot be undone
                      </p>

                      <div class="d-flex justify-space-between" fluid>
                        <v-btn depressed class="btn outline-btn font-weight-medium" width="157px" rounded @click="() => {
                          deleteDialog = false;
                          formbtnBool = false;
                        }
                          ">CANCEL</v-btn>
                        <v-btn class="btn primary-btn" depressed outlined width="157px" rounded
                          :disabled="selectedTableItems.length == 0" :loading="dLoading"
                          @click="deleteData(selectedTableItems)">DELETE</v-btn>
                      </div>
                    </v-card-text>
                  </v-container>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table :loading="isLoading" v-model="selectedTableItems" :headers="headers" height="calc(100vh - 230px)"
          fixed-header :items="newFilteredQuestions" show-select :single-select="singleSelect" item-key="id"
          :items-per-page="-1" :options.sync="dataTableOptions" :server-items-length="newQuestionCount" :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100],
          }">

          <template v-slot:[`item.questionStatement`]="{ item }">
            <div v-html="item.questionStatement"></div>
          </template>
          <template v-slot:[`item.MyLo`]="{}">
            <span><v-chip>{{ dummyLO[0].name }}</v-chip></span>
            <span v-if="dummyLO.length > 1"><v-chip>+{{ dummyLO.length - 1 }}</v-chip></span>
          </template>
          <template v-slot:[`item.createdOn`]="{ item }">
            {{ getDate(item.createdOn) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div>
              <img width="36px" height="36px" @click="updateData(item)" class="cursor" src="../assets/edit.svg" />
              <img width="36px" height="36px" @click="previewQuestion(item)" class="cursor ml-2" src="../assets/preview_1.svg" />
            </div>
          </template>
        </v-data-table>


        <!-- Create Question Dialog Form -->

        <!-- Filter Dialog -->

        <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
          <v-card width="570px" height="100vh" class="filterDialogClass">
            <v-row class="mt-4 mx-1">
              <v-col>
                <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                  Filter
                </div>
              </v-col>
              <v-col class="d-flex justify-end">
                <div @click="clearFilter" class="clear-filter-btn">
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>

            <v-tabs vertical>
              <div class="ml-3 mt-4 mr-4 filterLine">
                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Question Type</span>
                  <span v-if="selectedQuestionTypeFilter.length > 0" class="ml-1">({{ selectedQuestionTypeFilter.length
                    }})</span></v-tab>

                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Level</span>
                  <span v-if="selectedLevelFilter.length > 0" class="ml-1">({{ selectedLevelFilter.length
                    }})</span></v-tab>

                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Grade</span>
                <span v-if="selectedGradeFilter.length > 0" class="ml-1">({{ selectedGradeFilter.length
                  }})</span></v-tab>
                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Difficulty Level</span>
                  <span v-if="selecteddifficultyLevelFilter.length > 0" class="ml-1">({{
                    selecteddifficultyLevelFilter.length }})</span></v-tab>

                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Complexity Level</span>
                  <span v-if="selectedcomplexityLevelFilter.length > 0" class="ml-1">({{
                    selectedcomplexityLevelFilter.length }})</span></v-tab>

                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Knowledge Level</span>
                  <span v-if="selectedknowlewdgeLevelFilter.length > 0" class="ml-1">({{
                    selectedknowlewdgeLevelFilter.length }})</span></v-tab>

                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Blooms Taxonomy Level</span>
                  <span v-if="selectedbloomsTaxonomyLevelFilter.length > 0" class="ml-1">({{
                    selectedbloomsTaxonomyLevelFilter.length }})</span></v-tab>

                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Skills</span>
                  <span v-if="selectedskillsFilter.length > 0" class="ml-1">({{ selectedskillsFilter.length
                    }})</span></v-tab>

                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Subjects</span>
                  <span v-if="selectedsubjectsFilter.length > 0" class="ml-1">({{ selectedsubjectsFilter.length
                    }})</span></v-tab>

                <v-tab class="black--text justify-start" active-class="activeTab">
                  <span>Reported Questions</span>
                  <span v-if="selectedReportedQuestionsFilter" class="ml-1">(1)</span></v-tab>
              </div>

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card-text>
                    <v-chip-group v-model="selectedQuestionTypeFilter" active-class="active-chip" column :multiple="true">
                      <v-chip v-for="(questionType, index) in questionTypeList" :key="index" :value="questionType"
                        elevated>
                        {{ questionType }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card-text>
                    <v-chip-group v-model="selectedLevelFilter" active-class="active-chip" column :multiple="true">
                      <v-chip v-for="(level, index) in levels" :key="index" :value="level.name" elevated>
                        {{ level.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card
                v-if="selectedLevelFilter.length > 0"
                class="school-filter"
              >
                  <v-card-text>
                    <v-chip-group v-model="selectedGradeFilter" active-class="active-chip" column :multiple="true">
                      <v-chip v-for="(grade, index) in gradesTemp" :key="index" :value="grade" elevated>
                        {{ grade }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
                <v-card
                elevation="0"
                v-if="selectedLevelFilter.length == 0"
                class="school-filter"
              >
                <v-card-text class="d-flex justify-center">
                  Please Select Level First
                </v-card-text>
                </v-card>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card-text>
                    <v-chip-group v-model="selecteddifficultyLevelFilter" active-class="active-chip" column
                      :multiple="true">
                      <v-chip v-for="(difficultyLevel, index) in difficultyLevels" :key="index" :value="difficultyLevel"
                        elevated>
                        {{ difficultyLevel }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card-text>
                    <v-chip-group v-model="selectedcomplexityLevelFilter" active-class="active-chip" column
                      :multiple="true">
                      <v-chip v-for="(complexityLevel, index) in complexityLevels" :key="index" :value="complexityLevel"
                        elevated>
                        {{ complexityLevel }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card-text>
                    <v-chip-group v-model="selectedknowlewdgeLevelFilter" active-class="active-chip" column
                      :multiple="true">
                      <v-chip v-for="(knowlewdgeLevel, index) in knowledgeLevels" :key="index" :value="knowlewdgeLevel"
                        elevated>
                        {{ knowlewdgeLevel }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card-text>
                    <v-chip-group v-model="selectedbloomsTaxonomyLevelFilter" active-class="active-chip" column
                      :multiple="true">
                      <v-chip v-for="(
                          bloomsTaxonomyLevel, index
                        ) in bloomsTaxonomyLevel" :key="index" :value="bloomsTaxonomyLevel" elevated>
                        {{ bloomsTaxonomyLevel }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card-text>
                    <v-chip-group v-model="selectedskillsFilter" active-class="active-chip" column :multiple="true">
                      <v-chip v-for="(skill, index) in skills" :key="index" :value="skill.name" elevated>
                        {{ skill.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card-text>
                    <v-chip-group v-model="selectedsubjectsFilter" active-class="active-chip" column :multiple="true">
                      <v-chip v-for="(subject, index) in subjects" :key="index" :value="subject.name" elevated>
                        {{ subject.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              

              <v-tab-item>
                <v-card flat height="580px" id="myScroll">
                  <v-card-text>
                    <v-chip-group v-model="selectedReportedQuestionsFilter" active-class="active-chip" column 
                      :multiple="false"
                      >
  
                      <v-chip v-for="(question, index) in reportedQuestions" :key="index" :value="question.value" elevated>
                      {{ question.label }}
                    </v-chip>
                     <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" elevated active-class="active-chip">
                Custom Dates 
              </v-chip>
            </template>
            <v-card>
              <v-card-title>Select Dates</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-menu ref="menu" v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-bind="attrs" v-on="on" label="Start Date" prepend-icon="mdi-calendar" v-model="customStartDate" readonly></v-text-field>
                      </template>
                      <v-date-picker v-model="customStartDate" @input="menuStart = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu ref="menu" v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-bind="attrs" v-on="on" label="End Date" prepend-icon="mdi-calendar" v-model="customEndDate" readonly></v-text-field>
                      </template>
                      <v-date-picker v-model="customEndDate" @input="menuEnd = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn class="btn primary-btn" @click="addCustomDates">OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>

            <div class="filterDialogButton">
              <v-card-actions class="px-6 pb-3">
                <v-spacer></v-spacer>
                <v-btn rounded depressed class="btn outline-btn font-weight-medium pa-4"
                  @click="filterDialog = false">Cancel</v-btn>
                <v-btn rounded class="btn primary-btn pa-4" @click="
                  
                  (filterDialog = false),
                  filterQuestions(
                    selectedQuestionTypeFilter,
                    selectedLevelFilter,
                    selectedGradeFilter,
                    selecteddifficultyLevelFilter,
                    selectedcomplexityLevelFilter,
                    selectedknowlewdgeLevelFilter,
                    selectedbloomsTaxonomyLevelFilter,
                    selectedskillsFilter,
                    selectedsubjectsFilter,
                    selectedReportedQuestionsFilter
                  ),
                  getFilterCount()
                  ">Apply</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>

        <!-- <v-dialog v-model="filterDialog" max-width="400px">
        <v-card width="400px" height="100%">
          <v-card-text class="pa-6">
            <v-row>
              <v-col>
                <div class="pl-1 text-body1 font-weight-bold">FILTER</div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div
                  class="text-body1 font-weight-bold black--text cursor"
                  @click="clearFilter"
                >
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>
            <div>
              <v-card
                height="450px"
                elevation="0"
                id="myScroll"
                class="pt-5 ,pb-5"
              >
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text">
                      Question Type
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selectedQuestionTypeFilter"
                  active-class="btn-color white--text"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(questionType, index) in questionTypeList"
                    :key="index"
                    :value="questionType"
                    elevated
                  >
                    {{ questionType }}
                  </v-chip>
                </v-chip-group>

                  <v-row class="pl-1">
                    <v-col>
                      <div
                        class="text-body1 font-weight-normal black--text pt-3"
                      >
                        Level
                      </div>
                    </v-col>
                  </v-row>
                  <v-chip-group
                    v-model="selectedLevelFilter"
                    active-class="btn-color white--text"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(level, index) in levels"
                      :key="index"
                      :value="level.name"
                      elevated
                    >
                      {{ level.name }}
                    </v-chip>
                  </v-chip-group>

                  <v-row class="pl-1">
                    <v-col>
                      <div
                        class="text-body1 font-weight-normal black--text pt-3"
                      >
                        Difficulty Level
                      </div>
                    </v-col>
                  </v-row>
                  <v-chip-group
                    v-model="selecteddifficultyLevelFilter"
                    active-class="btn-color white--text"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(difficultyLevel, index) in difficultyLevels"
                      :key="index"
                      :value="difficultyLevel"
                      elevated
                    >
                      {{ difficultyLevel }}
                    </v-chip>
                  </v-chip-group>

                  <v-row class="pl-1">
                    <v-col>
                      <div
                        class="text-body1 font-weight-normal black--text pt-3"
                      >
                        Complexity Level
                      </div>
                    </v-col>
                  </v-row>
                  <v-chip-group
                    v-model="selectedcomplexityLevelFilter"
                    active-class="btn-color white--text"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(complexityLevel, index) in complexityLevels"
                      :key="index"
                      :value="complexityLevel"
                      elevated
                    >
                      {{ complexityLevel }}
                    </v-chip>
                  </v-chip-group>

                  <v-row class="pl-1">
                    <v-col>
                      <div
                        class="text-body1 font-weight-normal black--text pt-3"
                      >
                        Knowlewdge Level
                      </div>
                    </v-col>
                  </v-row>
                  <v-chip-group
                    v-model="selectedknowlewdgeLevelFilter"
                    active-class="btn-color white--text"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(knowlewdgeLevel, index) in knowledgeLevels"
                      :key="index"
                      :value="knowlewdgeLevel"
                      elevated
                    >
                      {{ knowlewdgeLevel }}
                    </v-chip>
                  </v-chip-group>

                  <v-row class="pl-1">
                    <v-col>
                      <div
                        class="text-body1 font-weight-normal black--text pt-3"
                      >
                        Blooms Taxonomy Level
                      </div>
                    </v-col>
                  </v-row>
                  <v-chip-group
                    v-model="selectedbloomsTaxonomyLevelFilter"
                    active-class="btn-color white--text"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(
                        bloomsTaxonomyLevel, index
                      ) in bloomsTaxonomyLevel"
                      :key="index"
                      :value="bloomsTaxonomyLevel"
                      elevated
                    >
                      {{ bloomsTaxonomyLevel }}
                    </v-chip>
                  </v-chip-group>

                  <v-row class="pl-1">
                    <v-col>
                      <div
                        class="text-body1 font-weight-normal black--text pt-3"
                      >
                        Skills
                      </div>
                    </v-col>
                  </v-row>
                  <v-chip-group
                    v-model="selectedskillsFilter"
                    active-class="btn-color white--text"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(skill, index) in skills"
                      :key="index"
                      :value="skill.name"
                      elevated
                    >
                      {{ skill.name }}
                    </v-chip>
                  </v-chip-group>

                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text pt-3">
                      Subjects
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selectedsubjectsFilter"
                  active-class="btn-color white--text"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(subject, index) in subjects"
                    :key="index"
                    :value="subject.name"
                    elevated
                  >
                    {{ subject.name }}
                  </v-chip>
                </v-chip-group>
              </v-card>
              <div>
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    outlined
                    class="pa-4"
                    @click="filterDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    rounded
                    class="accent pa-4"
                    @click="
                      filterQuestions(
                        selectedQuestionTypeFilter,
                        selectedLevelFilter,
                        selecteddifficultyLevelFilter,
                        selectedcomplexityLevelFilter,
                        selectedknowlewdgeLevelFilter,
                        selectedbloomsTaxonomyLevelFilter,
                        selectedskillsFilter,
                        selectedsubjectsFilter
                      )
                    "
                    >Apply</v-btn
                  >
                </v-card-actions>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog> -->
        <!-- Success Dialog -->
        <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
          <v-card>
            <v-container fluid class="pa-8">
              <v-card-text class="text-center d-flex flex-column align-center">
                <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
                <p class="text-h5 font-weight-bold py-4">
                  {{ successMessage }}
                </p>
                <v-btn class="btn primary-btn" large width="157px" rounded @click="successDialog = false">OK</v-btn>
              </v-card-text>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
      <!-- Error Dialog -->
      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Question Deleted</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Edit Option Dialog -->
      <v-dialog max-width="887px" v-model="optionTextFieldDialog" center>
        <v-card>
          <v-card-title class="gray-02 mb-8">Edit Option</v-card-title>
          <v-card-text>
            <!-- <v-text-field
            outlined
            class="rounded-xl"
            v-model="editedOption.value"
            label="Enter Option*"
          ></v-text-field> -->
            <ckeditor :config="editorConfigForOption" v-model="editedCkeditorValue" class="m-ckeditor"  @ready="onEditorReady"></ckeditor>
            <p v-if="!isValid" class="error-message red--text">Please enter a valid value (maximum 200 characters).</p>
          </v-card-text>
          <v-card-actions class="px-6 pb-6">
            <v-spacer></v-spacer>
            <v-btn depressed rounded class="btn outline-btn font-weight-medium pa-4"
              @click="optionTextFieldDialog = false">Cancel</v-btn>
            <v-btn depressed rounded @click="saveEditedValue" class="btn primary-btn pa-4" :disabled="!isValid">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Uploading Bulk Questions Dialog-->
      <v-dialog max-width="600px" max-height="80vh" v-model="uploadingQuestionsDialog" center>
        <v-card class="pa-4">
          <v-row>
            <v-col cols="6" md="6">
              <v-card-title class="pa-0 ma-0">
                Uploading... {{ bulkQuestionUploadResponse.length }} /
                {{ bulkQuestionJsonData.length }}
              </v-card-title>
            </v-col>
            <v-col cols="6" md="6">
              <v-btn class="float-right" icon
                @click="uploadingQuestionsDialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-data-table :headers="headersresponce" :items="bulkQuestionUploadResponse" :items-per-page="-1">
            <template v-slot:[`item.status`]="{ item }">
              <div class="d-flex flex-row ml-2">
                {{ item.status }}

                <v-icon v-if="item.status.includes('Uploaded Successfully!')" color="green">mdi-check-all</v-icon>
                <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
      <div v-if="imagePreview">
        <template>
          <v-dialog v-model="imagePreview">
            <v-card>
              <div class="d-flex justify-center align-center flex-column">
                <v-img :src="questionAssetUrlForPreview" @loadstart="resolveQuestionAssetUrl" alt="question asset"
                  class="img pa-0 w-auto h-auto" />
              </div>
              <div class="d-flex justify-end pb-2">
                <v-btn class="btn outline-btn font-weight-medium mx-3 text-right" rounded text
                  @click="imagePreview = false; questionAssetUrlForPreview=IMAGE_ALT_SRC">Cancel</v-btn>
              </div>
            </v-card>
          </v-dialog>
        </template>
      </div>
    </v-container>
  </div>
</template>

<script>
import AuthService from "../services/AuthService";
import axios from "axios";
import QuestionsController from "../controllers/QuestionsController";
import LoBankController from "@/controllers/LoBankController";
import AudienceRoleController from "@/controllers/AudienceRoleController";
import SubjectController from "@/controllers/SubjectController";
import SkillsController from "@/controllers/SkillsController";
import LevelController from "@/controllers/LevelController";
import Papa from "papaparse";
import { storage } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import Notification from "./Notification";
import { debounce } from "lodash";
import GradeController from "@/controllers/GradeController";
import { format, startOfToday, startOfYesterday, startOfWeek, startOfMonth, startOfLastWeek, startOfLastMonth, endOfToday, endOfYesterday, endOfWeek, endOfMonth, endOfLastWeek, endOfLastMonth } from 'date-fns';


const IMAGE_ALT_SRC = '../assets/caution.png'

export default {
  name: "QuestionBankView",
  components: {
    Notification,
  },
  data() {
    return {
      loadingQuestionsToDownload: false,
      tempOptions: new Map(),
      newFilteredQuestions: [],
      newQuestionCount: 0,
      notificationDialog: false,
      appliedFilter: false,
      uploadingQuestionsDialog: false,
      mainImage: true,
      editorConfig: {
        extraPlugins: "ckeditor_wiris",
      },
      editorConfigForOption: {
        extraPlugins: "ckeditor_wiris",
      },
      bulkQuestionJsonData: [],
      filterData: false,
      windowHeight: window.innerHeight,
      dataTableOptions: {},
      totalItems: 0,
      pageSize: 10,
      page: 1,
      count: 0,
      preSignedUrl: "",
      selectedFile: null,
      questionAssetUrl: "NA",
      questionAssetType: null,
      uploadingForOption: false,
      tableData: [],
      isLoading: true,
      algoliaIndex: null,
      selectedLOs: [],
      selectedTags: null,
      LOData: [],
      checkData: {
        skills: [],
        levels: [],
        grades: [],
        subjects: [],
      },
      skills: [],
      search: this.$route.query.search || "",
      dLoading: false,
      editId: null,
      formbtnBool: false,
      subjectData: [],
      selectedAnswersForMultitpleTypeQuestions: [],
      searchLOs: "",
      searchSubjects: "",
      singleSelect: false,
      createQuestionDialog: false,
      deleteDialog: false,
      optionTextFieldDialog: false,
      editedOptionIndex: 0,
      editedCkeditorValue: "",
      successDialog: false,
      deleteSuccessDialog: false,
      successMessage: "New Question Created",
      errorDialog: false,
      filterDialog: false,
      filterCount: 0,
      selectedTableItems: [],
      bulkQuestionUploadResponse: [],
      uploadingDialog: false,
      uploadingMsg: "File Uploading...",
      questionType: "SINGLE_CHOICE",
      questionName: "",
      answerExplanation: "",
      filterVal: "",
      filterVal: "",
      removedQuestion: [],
      questionDescription: "NA",
      errorMessage: "",
      skill: 0,
      level: 0,
      searchBool: false,
      tag: "",
      correctAnswerScore: 0,
      estimatedTime: 0,
      bloomsTaxonomy: "",
      knowledgeLevel: "",
      complexityLevel: "",
      difficultyLevel: "",
      correctAnswer: "NA",
      hint: "",
      subjectShowBool: false,
      subject: null,
      proficiencyLevel: "",
      isCreatingQuestion: false,
      dummyLO: [{ name: "lO1_1" }, { name: "lO1_2" }, { name: "lO1_3" }],
      rules: {
        required: (value) => !!value || "Field is required",
      },
      options: [
        {
          optionKey: "A",
          optionValue: "Option 1",
        },
        {
          optionKey: "B",
          optionValue: "Option 2",
        },
        {
          optionKey: "C",
          optionValue: "Option 3",
        },
        {
          optionKey: "D",
          optionValue: "Option 4",
        },
      ],
      headers: [
        { text: "ID", value: "questionId", cellClass: "w-auto" },
        { text: "Question", value: "questionStatement", cellClass: "Qw-40" },
        { text: "Type", value: "questionType", cellClass: "w-15" },
        { text: "Skills", value: "skill", cellClass: "w-15" },
        { text: "Difficulty", value: "difficultyLevel", cellClass: "w-15" },
        { text: "Actions", value: "actions", cellClass: "w-10" },
      ],
      imagePreview: false,
      headersresponce: [
        { text: "Question Index in CSV", value: "questionIndex" },
        { text: "Status", value: "status" },
      ],
      questionTypeList: [
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "FILL_IN_THE_BLANKS",
        "TRUE_FALSE",
        "MATCH_THE_FOLLOWING",
      ],
      selectedQuestionTypeFilter: [],
      product: null,
      productOptions: ["MESTA", "SRS", "Hubble STAR", "LMS"],
      questionid: "",
      los:[], 
      hrisUniqueRole: [],
      groupNames: ["Academic", "Non-Academic"],
      isCaseSensitive: "No",
      marksAllotted: null,
      languageOfQuestion: "",
      languageOptions: ["English", "Hindi", "Marathi"],
      levels: [],
      levelsGrades: [],
      grades: [],
      gradesTemp: [],
      singleSelectCorrectAnswer: null,
      trueFalseCorrectAnswer: null,
      selectedLevelFilter: [],
      difficultyLevels: ["EASY", "MEDIUM", "HARD", "VERY_HARD"],
      selectedGradeFilter:[],
      selecteddifficultyLevelFilter: [],
      complexityLevels: ["P1", "P2", "P3", "P4", "P5"],
      selectedcomplexityLevelFilter: [],
      knowledgeLevels: ["MUST_KNOW", "SHOULD_KNOW", "NICE_TO_KNOW"],
      selectedknowlewdgeLevelFilter: [],
      bloomsTaxonomyLevel: [
        "REMEMBER",
        "UNDERSTAND",
        "APPLY",
        "ANALYSE",
        "EVALUATE",
        "CREATE",
      ],
      selectedbloomsTaxonomyLevelFilter: [],
      selectedskillsFilter: [],
      subjects: [],
      selectedsubjectsFilter: [],
      selectedlearningObjectives: [],
      tagsList: [],
      selectedtags: [],
      mtfAnswers: [
        {
          key: 1,
          value: "Answer 1",
          type: "TEXT",
        },
        {
          key: 2,
          value: "Answer 2",
          type: "TEXT",
        },
        {
          key: 3,
          value: "Answer 3",
          type: "TEXT",
        },
        {
          key: 4,
          value: "Answer 4",
          type: "TEXT",
        },
      ],
      preloader: false,
      debouncedSearch: null,
      grade: null,
      strand: null,
      subStrand: null,
      topic: null,
      questionAssetUrlForPreview: IMAGE_ALT_SRC,
      IMAGE_ALT_SRC,
      reportedQuestions: [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'This Week', value: 'this_week' },
        { label: 'Last Week', value: 'last_week' },
        { label: 'This Month', value: 'this_month' },
        { label: 'Last Month', value: 'last_month' },
      ],
      selectedReportedQuestionsFilter: null,
      menu: false,
      menuStart: false,
      menuEnd: false,
      customStartDate: null,
      customEndDate: null,
      isCustomDate :false,
      isCustom:false,
      sendSelectedReportedQuestionsFilter:[],
      // typeOfAudience: null,
      // typeOfAudienceOptions: [{ name: "Student" }, { name: "Teacher" }, { name: "Both" }],
      authoredBy: null,
      authoredByOptions: [{ name: "HubbleHox", value: "HH" }, { name: "Vendor Acquired", value : "VA" }, 
      { name: "Acquired from Publications", value: "AP" }, { name: "Acquired From Book", value: "AB" }, { name: "Practice guide", value : "PG" }, 
      { name: "Vertex", value: "VERTEX" }],
      // Add a map to track questionId-language combinations
      questionIdLanguageCombos: new Map(),
      questionIdError: "",
      questionIdRules: [
        v => !!v || 'Question ID is required',
        v => this.validateQuestionId(v) || 'Question ID must include the product prefix'
      ],
    };
  },
  watch: {
    dataTableOptions: {
      handler() {
        console.log("dataTableOptions entered", this.dataTableOptions);
        this.pageSize = this.dataTableOptions.itemsPerPage;
        this.page = this.dataTableOptions.page;

        this.getNewQuestionsData();
      },
      deep: true,
    },
    
    search(newValue) {
      console.log("search Val :", newValue);
      if (newValue === null)
        this.search = ""
      this.debouncedSearch();
    },

    tag(newValue) {
      console.log(newValue.indexOf(","));
      if (newValue.indexOf(",") != -1) {
        var temp = newValue.split(",")[0];
        if (temp != "") {
          this.tagsList.push(temp);
        }
        this.tag = "";
        console.log("value inside", this.tag);
      }
      console.log("index value", newValue.indexOf(","));
      console.log("outside", this.tag);
    },
    selectedLevelFilter: {
   handler(newValue, oldValue) {
    console.log("selectedLevelFilter", newValue);
    
    // Handle additions
    newValue.forEach(levelName => {
      if (!oldValue.includes(levelName)) {
        const newLevel = this.levelsGrades.find(level => level.name === levelName);
        if (newLevel && newLevel.grades) {
          this.gradesTemp = [...new Set([...this.gradesTemp, ...newLevel.grades])];
        }
      }
    });
    // Handle removals
    oldValue.forEach(levelName => {
      if (!newValue.includes(levelName)) {
        const removedLevel = this.levelsGrades.find(level => level.name === levelName);
        if (removedLevel && removedLevel.grades) {
          this.gradesTemp = this.gradesTemp.filter(grade => 
            newValue.some(selectedLevelName => {
              const level = this.levelsGrades.find(l => l.name === selectedLevelName);
              return level && level.grades.includes(grade);
            })
          );
        }
      }
    });
    this.gradesTemp.sort();
  }
  },
},
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    if (this.$store.state.role.createdOn == undefined) {
      this.logout();
      if (this.$store.state.role.createdOn == undefined) {
        this.logout();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    isValid() {
      return this.getTextWithoutHTMLTags(this.editedCkeditorValue).length <= 200;
    },
    getHeight() {
      return this.windowHeight;
    },
  },
  methods: {
    addCustomDates() {
      this.isCustomDate = true;
      
      if (this.customStartDate && this.customEndDate) {
        this.sendSelectedReportedQuestionsFilter = {
          start: format(new Date(this.customStartDate), 'yyyy-MM-dd 00:00:00'),
          end: format(new Date(this.customEndDate), 'yyyy-MM-dd 23:59:59')
        };
        this.menu = false;
      }
    },
    onEditorReady(editor) {
      editor.on("afterCommandExec", function(event) {
        var commandName = event.data.name;
        if(['ckeditor_wiris_openFormulaEditor', 'ckeditor_wiris_openFormulaEditorChemistry'].includes(commandName) ) {
          setTimeout(() => {
              const wiris_license_overlays = document.querySelectorAll('.wrs_tickContainer')
              wiris_license_overlays.forEach(element => {
                element.remove();
              });
            }, 2000)
        }
      })
    },
    restorePrevValue(index) {
      this.options[index].optionValue = this.tempOptions.get(index)
    },
    closeChip() {
      this.mainImage = false;
      this.questionAssetUrl = 'NA';
    },
    openImagePreviewDialog() {
      this.imagePreview = true
    },
    async downloadFile() {

      let fileName = 'Hubble STAR - Question Bank Sample File.csv'
      try {
        const response = await axios.get(`https://firebasestorage.googleapis.com/v0/b/vgos-sss-stag.appspot.com/o/question_bank%2F${fileName}?alt=media`, {
          responseType: 'blob' // Important
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error downloading file:', error);
      }

    },
    getDateRange() {
      switch (this.selectedReportedQuestionsFilter) {
        case 'today':
          this.sendSelectedReportedQuestionsFilter = {
            start: format(startOfToday(), 'yyyy-MM-dd 00:00:00'),
            end: format(endOfToday(), 'yyyy-MM-dd 23:59:59')
          };
          break;
        case 'yesterday':
          this.sendSelectedReportedQuestionsFilter = {
            start: format(startOfYesterday(), 'yyyy-MM-dd 00:00:00'),
            end: format(endOfYesterday(), 'yyyy-MM-dd 23:59:59')
          };
          break;
        case 'this_week':
          this.sendSelectedReportedQuestionsFilter = {
            start: format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd 00:00:00'),
            end: format(endOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd 23:59:59')
          };
          break;
        case 'last_week':
          const lastWeekDate = new Date(new Date().setDate(new Date().getDate() - 7));
          this.sendSelectedReportedQuestionsFilter = {
          start: format(startOfWeek(lastWeekDate, { weekStartsOn: 1 }), 'yyyy-MM-dd 00:00:00'),
          end: format(endOfWeek(lastWeekDate, { weekStartsOn: 1 }), 'yyyy-MM-dd 23:59:59')
          };
          break;
        case 'this_month':
          this.sendSelectedReportedQuestionsFilter = {
            start: format(startOfMonth(new Date()), 'yyyy-MM-dd 00:00:00'),
            end: format(endOfMonth(new Date()), 'yyyy-MM-dd 23:59:59')
          };
          break;
        case 'last_month':
          const lastMonthDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
          this.sendSelectedReportedQuestionsFilter = {
            start: format(startOfMonth(lastMonthDate), 'yyyy-MM-dd 00:00:00'),
            end: format(endOfMonth(lastMonthDate), 'yyyy-MM-dd 23:59:59')
          };
          break;
        default:
          this.sendSelectedReportedQuestionsFilter = [];
          break;
      }
    },
    async getNewQuestionsData() {
  if (!this.isCustomDate) {
    this.getDateRange();
  }
  try {
    this.isLoading = true;
    this.newQuestionCount = 0;
    
    const params = {
      pgNum: this.page,
      sortCol: "",
      sortOrder: "asc",
      itemsPerPage: this.pageSize,
      searchQuery: this.search,
      questionType: JSON.stringify(this.selectedQuestionTypeFilter || []),
      level: JSON.stringify(this.selectedLevelFilter || []),
      grades: JSON.stringify(this.selectedGradeFilter || []),
      difficultyLevel: JSON.stringify(this.selecteddifficultyLevelFilter || []),
      complexityLevel: JSON.stringify(this.selectedcomplexityLevelFilter || []),
      knowledgeLevel: JSON.stringify(this.selectedknowlewdgeLevelFilter || []),
      taxonomyLevel: JSON.stringify(this.selectedbloomsTaxonomyLevelFilter || []),
      skill: JSON.stringify(this.selectedskillsFilter || []),
      subject: JSON.stringify(this.selectedsubjectsFilter || []),
      reportedQuestion: JSON.stringify(this.sendSelectedReportedQuestionsFilter || [])
    };

    const res = await QuestionsController.getFilteredQuestions(
      params.pgNum,
      params.sortCol,
      params.sortOrder,
      params.itemsPerPage,
      params.searchQuery,
      JSON.parse(params.questionType),
      JSON.parse(params.level),
      JSON.parse(params.grades),
      JSON.parse(params.difficultyLevel),
      JSON.parse(params.complexityLevel),
      JSON.parse(params.knowledgeLevel),
      JSON.parse(params.taxonomyLevel),
      JSON.parse(params.skill),
      JSON.parse(params.subject),
      JSON.parse(params.reportedQuestion)
    );
    
    if (res.flag) {
      this.newFilteredQuestions = res.data.questions;
      this.newQuestionCount = res.data.totalQuestions;
      this.isLoading = false;
      this.preloader = false;
    }
  } catch (err) {
    console.log(err);
  } finally {
    this.isLoading = false;
    this.isCustomDate = false;
  }
  this.updateUrlParams();
},
    convertOptionsToArray(options) {
      let result = {};
      options.forEach((option, index) => {
        const optionIndex = index + 1;
        result[`optionValue${optionIndex}`] = option.optionValue;
        result[`optionKey${optionIndex}`] = option.optionKey;
      });
      return result;
    },
    async exportQuestions(csvData, fileName) {
      const csv = Papa.unparse(csvData, {
        quotes: true,  // or array of booleans
        quoteChar: '"',
        escapeChar: '"'
      });

      const bom = '\uFEFF';
      const blob = new Blob([bom + csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      this.loadingQuestionsToDownload = false;
    },
    formatDate(dateString) {
      return format(new Date(dateString), 'yyyy-MM-dd');
    },
    async downloadQuestions() {
      this.loadingQuestionsToDownload = true;
      const sanitizedSearch = this.search.replace(/'/g, '&#39;'); 
      const res = await QuestionsController.exportQuestions("", "asc", sanitizedSearch, this.selectedQuestionTypeFilter, this.selectedLevelFilter, this.selectedGradeFilter, this.selecteddifficultyLevelFilter, this.selectedcomplexityLevelFilter, this.selectedknowlewdgeLevelFilter, this.selectedbloomsTaxonomyLevelFilter, this.selectedskillsFilter, this.selectedsubjectsFilter,this.sendSelectedReportedQuestionsFilter)
      
      const questionsToExport = res.data.questions;  
      const fileName = "questions.csv";
      const dataForDownload = [];
      const questionCount = {};
      if (res.data.isReportedQuestions){
        let serialNo = 1;
        for (const val of questionsToExport) {
            val.questionStatement = val.questionStatement.replace(/<\/?[^>]+(>|$)/g, "");

            if (questionCount[val.questionId]) {
              questionCount[val.questionId].noOfUserReportedQuestion += 1;
                questionCount[`${val.questionId}-${questionCount[val.questionId].noOfUserReportedQuestion}`] = {
                  serialNo: serialNo++,
                  questionId: val.questionId,
                  testRound: '',
                  level: '',
                  grade: '',
                  skill: '',
                  questionStatement: '',
                  noOfUserReportedQuestion: '',
                  nameOfUser: val.nameOfUser,
                  campaignName: val.campaignName,
                  reportedComment: val.reportedComment,
                  dateReported: this.formatDate(val.dateReported),
              };
            } else {
              questionCount[val.questionId] = {
                serialNo: serialNo++,
                questionId: val.questionId,
                testRound: val.testRound,
                level: val.level,
                grade: val.grade,
                skill: val.skill,
                questionStatement: val.questionStatement,
                noOfUserReportedQuestion: 1,
                nameOfUser: val.nameOfUser,
                campaignName: val.campaignName,
                reportedComment: val.reportedComment,
                dateReported: this.formatDate(val.dateReported),
            };
          }
        }

        for (const key in questionCount) {
          dataForDownload.push(questionCount[key]);
        }
      }
      else {
        for (const val of questionsToExport) {
          val.questionStatement = val.questionStatement.replace(/<\/?[^>]+(>|$)/g, "");
          const questionOptions = this.convertOptionsToArray(val.questionOptions);

          const authoredByObj = this.authoredByOptions.find(option => option.value === val.authoredBy);
          const authoredByName = authoredByObj ? authoredByObj.name : val.authoredBy;
          const obj = {
            questionId: val.id,
            level: val.level,
            grade: val.grade,
            skill: val.skill,
            subject: val.subject,
            strand: val.strand,
            subStrand: val.subStrand,
            topic: val.topic,
            lo1:val.lo1,
            lo2:val.lo2,
            lo3:val.lo3,
            lo4:val.lo4,
            questionType: val.questionType?.toUpperCase(),
            questionStatement: val.questionStatement,
            questionAssetUrl: val.questionAssetUrl,
          }
          for (let i = 0; i <= (val.questionOptions.length / 2) + 1; i++) {
            const optionValue = questionOptions[`optionValue${i + 1}`];
              if (optionValue) {
                obj[`optionValue${i + 1}`] = optionValue.replace(/<\/?[^>]+(>|$)/g, "");
              } else {
                obj[`optionValue${i + 1}`] = '';
              }
              obj[`optionKey${i + 1}`] = questionOptions[`optionKey${i + 1}`];
          }
          obj.correctAnswer = val.correctAnswer,
          obj.answerExplanation = val.answerExplanation,
          obj.bloomsTaxonomy = val.bloomsTaxonomy?.toUpperCase(),
          obj.difficultyLevel = val.difficultyLevel?.toUpperCase(),
          obj.complexityLevel = val.complexityLevel,
          obj.hint = val.hint,
          obj.proficiencyLevel = val.proficiencyLevel,  
          // obj.typeOfAudience = val.typeOfAudience,                
          obj.authoredBy = authoredByName,
          dataForDownload.push(obj);
          }
      }
      dataForDownload.forEach(obj => {
        for (const key in obj) {
          if (typeof obj[key] === 'string') {
            obj[key] = this.decodeHtmlEntities(obj[key]);
          }
        }
      });
      this.exportQuestions(dataForDownload, fileName);
    },
    decodeHtmlEntities(text) {
      const entities = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': "'",
        '&nbsp;': ' ',
      };
      return text.replace(/&(amp|lt|gt|quot|#39|nbsp|#(\d+));/g, function(match, entity, dec) {
        if (entity === 'quot') return '"';
        if (entity === '#39') return "'";
        return entities[entity] || String.fromCharCode(dec);
      });
    },
    skippedQuestion(id, reason) {
      console.log("reason");
      if (reason == "") {
      } else {
        this.removedQuestion.push(
          "Q" + id + ". Failed" + "->reason-> " + reason
        );
      }
    },
    downloadTextFile(text, filename) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    multiSubjectCheck(subjects){
      return subjects.split(',').every((sub) => this.checkData.subjects.includes(sub.trim()))
    },
    checkMatters(headerName, word, skill, headers, cells) {
      // Remove asterisk from header name before checking
      const cleanHeaderName = headerName.replace('*', '').toLowerCase();
      const isRequired = headerName.includes('*');
      
      // Check for required fields - reject empty or 'NA' values
      if (isRequired && (!word || word.trim().toUpperCase() === 'NA')) {
        return false;
      }

      const productIndex = headers.map(h => h.replace('*', '')).indexOf("product");
      const currentProduct = cells[productIndex];

      switch (cleanHeaderName) {
        case 'questiontype':
          return this.questionTypeList.includes(word.trim());
        
        case 'product':
          return ["MESTA", "SRS", "Hubble STAR", "LMS"].includes(word.trim());
        
        case 'questionid':
          if (!word) return false;
          
          // Get language from current row - handle asterisk in header
          const languageIndex = headers.map(h => h.replace('*', '')).indexOf("languageOfQuestion");
          const language = cells[languageIndex]?.trim() || '';
          
          const comboKey = `${word.trim()}-${language}`;
          
          if (this.questionIdLanguageCombos.has(comboKey)) {
            return false;
          }
          
          const prefix = currentProduct.replace(' ', '').toUpperCase();
          const pattern = new RegExp(`^${prefix}_(\\d+|[a-zA-Z]\\d+)$`);
          const isValidFormat = pattern.test(word.toUpperCase().trim());
          
          if (isValidFormat) {
            this.questionIdLanguageCombos.set(comboKey, true);
          }
          
          return isValidFormat;
        
        case 'skill':
          return this.checkData.skills.includes(word.trim());
        
        case 'level':
          if (skill == "Core Skills") {
            return this.checkData.levels.includes(word.trim());
          }
          return true;
        
        case 'difficultylevel':
          return this.difficultyLevels.includes(word.toUpperCase().trim());
        
        case 'subject':
          if (!isRequired && (word === "NA" || word === "na")) {
            return true;
          }
          return /,/.test(word.trim()) ? this.multiSubjectCheck(word.trim()) : this.checkData.subjects.includes(word.trim());
        
        case 'hrisuniquerole':
          // Check if the role exists in audienceRoles collection
          console.log("word", word);
          console.log("hrisUniqueRole", this.hrisUniqueRole);
          console.log("includes", this.hrisUniqueRole.includes(word.trim()));
          return this.hrisUniqueRole.includes(word.trim());
        
        case 'groupname':
          return this.groupNames.includes(word.trim());
        
        case 'los':
          if (!word) return false;
          // Split the LO codes by comma and validate each
          const loCodes = word.split(',').map(code => code.trim());
          return loCodes.every(code => this.LOData.includes(code));
        case 'correctanswer':
          const questionTypeIndexCorrectAnswer = headers.indexOf("questionType");
          const questionTypeCorrectAnswer = cells[questionTypeIndexCorrectAnswer];
          
          if (questionTypeCorrectAnswer === 'MATCH_THE_FOLLOWING') {
            if (!word) return false;
            
            // Split by comma to get each pair
            const pairs = word.split(',').map(pair => pair.trim());
            
            // Validate each pair has exactly one colon
            return pairs.every(pair => {
              const parts = pair.split(':');
              // Each pair should have exactly 2 parts (value1:value2)
              // and neither part should be empty
              return parts.length === 2 && 
                     parts[0].trim().length > 0 && 
                     parts[1].trim().length > 0;
            });
          }
          return true; // For other question types
        
        default:
          return true;
      }
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    saveEditedValue() {
      this.options[this.editedOptionIndex].optionValue =
        this.editedCkeditorValue;
      this.optionTextFieldDialog = false;
    },
    getAlphabet(num) {
      // ASCII value for 'A' is 65
      // The alphabet letter corresponding to num can be calculated by adding num to 65 and then getting the character using fromCharCode()
      return String.fromCharCode(65 + num);
    },
    clearValue() {
      (this.questionName = ""),
        (this.questionDescription = "NA"),
        (this.selectedLOs = []),
        (this.skill = 0),
        (this.subject = null),
        (this.correctAnswerScore = 0),
        (this.estimatedTime = 0),
        (this.bloomsTaxonomy = ""),
        (this.knowledgeLevel = ""),
        (this.complexityLevel = ""),
        (this.difficultyLevel = ""),
        (this.proficiencyLevel = ""),
        (this.level = 0),
        (this.hint = ""),
        (this.answerExplanation = ""),
        (this.selectedTags = []),
        (this.formbtnBool = false);
      this.topic = null,
        this.grade = null,
        this.strand = null,
        this.subStrand = null,
        // this.typeOfAudience = null,
        this.authoredBy = null
      this.selectedHrisRoles = [];
    },
    checkSubjects(id) {
      if (id == null) {
        this.subjectShowBool = false;
      } else {
        console.log(id);
        const skill = this.skills.find((skill) => skill == id);
        // console.log(skill);
        if (skill?.subject_skills.length == 0) {
          this.subjectShowBool = false;
          if (!this.subjectShowBool) {
            this.subject = null;
            
          }
        } else {
          this.subjectShowBool = true;
        }
      }
    },
    async searchData(search) {
      const response = await QuestionsController.searchQuestion(
        this.pageSize,
        this.page,
        search
      );
      console.log(response.data);
      console.log(this.searchBool);
      this.count = response.data.data.count;
      this.tableData = response.data.data.rows;
    },

    addTag() {
      if (this.tag && !this.tagsList.includes(this.tag)) {
        this.tagsList.push(this.tag);
        this.tag = "";
      }
    },
    removeTag(index) {
      this.tagsList.splice(index, 1);
    },
    reload() {
      console.log("Reload");
      this.$router.go(0);
      //window.location.reload();
    },

    onResize() {
      this.windowHeight = window.innerHeight;
    },
    onChange(e) {
      this.tempOptions.set(this.editedOptionIndex, this.options[this.editedOptionIndex].optionValue)
      this.selectedFile = e.target.files[0];
      this.uploadingMsg = "File Uploading...";
      console.log("selected file", this.selectedFile);
      this.uploadingDialog = true;
      this.uploadAsset();
    },
    uploadAsset() {
      console.log;
      this.loading = true;
      if (this.selectedFile != null) {
        const storageRef = ref(
          storage,
          "/question_bank/" + this.selectedFile.name + new Date().getTime()
        );
        uploadBytes(storageRef, this.selectedFile).then((snapshot) => {
          console.log("Uploaded");
          getDownloadURL(snapshot.ref).then((url) => {
            if (this.uploadingForOption) {
              this.options[this.editedOptionIndex].optionValue = url;
              // this.options[this.editedOptionIndex].optionType = "IMAGE";
            } else {
              this.questionAssetUrl = url;
              this.mainImage = true
              // this.questionAssetType = this.selectedFile.type;
              // console.log(this.selectedFile.type);
            }
            this.uploadingForOption = false;
            this.uploadingMsg = "File uploaded";
            // console.log("Upload Response: ", uploadResponse);
            // this.saveInputs();
          });
        });
      }
    },

    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },

    async updateInput(id) {
      console.log("update called");
      if (this.$refs.form.validate() && this.questionName) {
        const sanitizedQuestionStatement = this.sanitizedQuestion(this.questionName)
        const response = await QuestionsController.updateQuestion(
          {
            questionStatement: this.questionName,
            sanitizedQuestionStatement,
            questionType: this.questionType,
            questionDescription: this.questionDescription,
            questionAssetUrl: this.questionAssetUrl,
            questionAssetType: this.questionAssetType,
            selectedLOs: this.selectedLOs,
            skill: this.skill,
            subject: this.subject,
            correctAnswerScore: this.correctAnswerScore,
            estimatedTime: this.estimatedTime,
            bloomsTaxonomy: this.bloomsTaxonomy,
            knowledgeLevel: this.knowledgeLevel,
            complexityLevel: this.complexityLevel,
            difficultyLevel: this.difficultyLevel,
            proficiencyLevel: this.proficiencyLevel,
            correctAnswer: this.getCorrectAnswer(),
            level: this.level,
            hint: this.hint,
            tags: this.selectedTags,
            answerExplanation: this.answerExplanation,
            questionOptions: this.getOptions(),
            topic: this.topic,
            strand: this.strand,
            subStrand: this.subStrand,
            grade: this.grade,
            // typeOfAudience: this.typeOfAudience,
            authoredBy: this.authoredBy,
            hrisUniqueRole: this.selectedHrisRoles
          },
          id
        );
        console.log("responce", response);
        console.log(response.data);
        if (response.status == 200) {
          this.isCreatingQuestion = false;
          this.successMessage = `Question Id ${id} has been updated Successfully`;
          this.successDialog = true;
          this.createQuestionDialog = false;
          //this.fetchQuestions();
        } else {
          this.isCreatingQuestion = false;
          this.errorDialog = true;
        }
      }
    },
    checkArrOptions(item = ``){
      let result = `${item}`.replace(/[^A-Za-z]/g, '');
      return Array.from(new Set(result))
    },
    updateData(item) {
      this.editId = item.id; // selected id for edit
      this.formbtnBool = true; // change update/create btn value
      this.createQuestionDialog = true;
      
      // Add these lines to set the missing fields
      this.product = item.product;
      this.questionid = item.questionId;
      this.languageOfQuestion = item.languageOfQuestion;
      this.groupName = item.groupName;
      this.skill = item.skill;
      this.questionType = item.questionType;
      this.estimatedTime = item.estimatedTime;
      this.bloomsTaxonomy = item.bloomsTaxonomy;
      this.knowledgeLevel = item.knowledgeLevel;
      this.complexityLevel = item.complexityLevel;
      this.difficultyLevel = item.difficultyLevel;
      this.hint = item.hint;
      this.subject = item.subject;
      this.proficiencyLevel = item.proficiencyLevel;
      this.level = item.level;
      this.options = item.questionOptions;
      this.singleSelectCorrectAnswer = item.correctAnswer;
      this.selectedAnswersForMultitpleTypeQuestions = this.checkArrOptions(item.correctAnswer);
      this.trueFalseCorrectAnswer = item.correctAnswer;
      this.correctAnswerScore = item.correctAnswerScore;
      this.questionAssetUrl = item.questionAssetUrl;
      this.selectedTags = item.tags;
      // this.selectedLOs = item.lo_ids data not comming
      this.answerExplanation = item.answerExplanation;
      this.grade = item.grade
      this.topic = item.topic
      this.strand = item.strand
      this.subStrand = item.subStrand
      // this.typeOfAudience = item.typeOfAudience
      this.authoredBy = item.authoredBy
      this.selectedHrisRoles = item.hrisUniqueRole;
      setTimeout(() => {
        // This code will execute after a 1 second delay
        this.questionName = item.questionStatement;
      }, 2000);
    },
    
    previewQuestion(question){
      const questionId = question.id;
      // Encode the question ID as base64
      const encodedId = btoa(questionId);
      const previewUrl = this.$router.resolve({ name: 'QuestionPreview', query: { id: encodedId, question: JSON.stringify(question) } }).href;
      const screenWidth = window.screen.availWidth;
      const screenHeight = window.screen.availHeight;
      const newWindow = window.open(previewUrl, '_blank', `width=${screenWidth},height=${screenHeight}`);
      
      // Check if the new window has opened successfully and request full screen
      if (newWindow) {
        newWindow.onload = () => {
          newWindow.document.documentElement.requestFullscreen();
        };
      } else {
        console.error("Failed to open new window.");
      }
    },
    
    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },

    async deleteData(data) {
      this.dLoading = true;
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id);
        const index = this.newFilteredQuestions.indexOf(dataDelete);
        this.newFilteredQuestions.splice(index, 1);
      });
      const response = await QuestionsController.deleteBulkQuestion(ids);
      if (response.data.flag) {
        setTimeout(this.fetchQuestions, 4000);

        this.dLoading = false;
        this.deleteDialog = false;
        this.selectedTableItems.length = "0";
        this.selectedTableItems = [];
        console.log("response", response);
      } else {
        this.dLoading = false;
        this.deleteDialog = false;
        this.selectedTableItems = [];
        this.selectedTableItems.length = "0";
        alert(response.data.error);
      }
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },

    //console.log("deleted", response);
    async getLO() {
      const response = await LoBankController.getLO();
      //console.log(response);
      this.LOData = response.data.learningObjective.map(lo => lo.lOCode);
    },
    async getAudienceRoles() {
      const response = await AudienceRoleController.getAudienceRoles();
      //console.log(response);
      this.hrisUniqueRole = response.data.audienceRoles.map(role => role.HRIS_unique_role);
      console.log("hrisUniqueRole", this.hrisUniqueRole);
    },
    async getGrades() {
      const response = await GradeController.getAllGrades();
      this.grades = response.data.grades;
      this.grades.forEach((obj)=>{
        this.checkData.grades.push(obj.name)
      })
    },
    async getLevels() {
      const response = await LevelController.getLevel();
      this.levelsGrades =  response.data.levels;
      this.levels = response.data.levels;
      this.levels.forEach((obj) => {
        this.checkData.levels.push(obj.name);
      });
    },
    async getSkills() {
      const response = await SkillsController.getSkills();
      //console.log(response);
      this.skills = response.data.skills.filter(skill=>skill.name !== "Psychometry");
      this.skills.forEach((obj) => {
        this.checkData.skills.push(obj.name);
      });
    },
    async getSubjects() {
      const response = await SubjectController.getSubject();
      this.subjects = response.data.subjects;
      console.log("subjects", this.subjects);
      this.subjects.forEach((obj) => {
        this.checkData.subjects.push(obj.name);
      });
    },
    addOption() {
      this.options.push({
        optionKey: this.getAlphabet(this.options.length),
        optionValue: "Option " + (this.options.length + 1),
      });
      console.log(this.options);
    },
    removeOption(index) {
      this.options.splice(index, 1);
    },
    getCorrectAnswer() {
      switch (this.questionType) {
        case "SINGLE_CHOICE":
          return this.singleSelectCorrectAnswer;
        case "MULTIPLE_CHOICE":
          return this.selectedAnswersForMultitpleTypeQuestions.join(',');

        case "FILL_IN_THE_BLANKS":
          return this.correctAnswer;

        case "TRUE_FALSE":
          return this.trueFalseCorrectAnswer;

        case "MATCH_THE_FOLLOWING":
          return JSON.stringify(this.mtfAnswers);

        default:
          break;
      }
    },
    getOptions() {
      switch (this.questionType) {
        case "SINGLE_CHOICE":
          return this.options;

        case "MULTIPLE_CHOICE":
          return this.options;

        case "FILL_IN_THE_BLANKS":
          return [];

        case "TRUE_FALSE":
          return [
            {
              optionKey: "A",
              optionValue: "TRUE",
            },
            {
              optionKey: "B",
              optionValue: "FALSE",
            },
          ];

        case "MATCH_THE_FOLLOWING":
          return this.options;

        default:
          break;
      }
    },
    // async createQuestionsFromBulk() {
    //   this.bulkQuestionJsonData.forEach(async (question) => {
    //     const response = await QuestionsController.createQuestion(question);
    //     if (response.status == 200) {
    //       console.log(question);
    //     }
    //   });
    // },
    async createQuestion() {
      if (this.$refs.form.validate() && this.questionName) {
        var selectTags = "";
        this.selectedTags.forEach((item) => {
          selectTags = selectTags + item + ",";
        });
        selectTags = selectTags.slice(0, -1);
        console.log(selectTags);
        this.isCreatingQuestion = true;
        const sanitizedQuestionStatement = this.sanitizedQuestion(this.questionName)
        const response = await QuestionsController.createQuestion({
          questionStatement: this.questionName,
          questionType: this.questionType,
          questionDescription: this.questionDescription,
          questionAssetUrl: this.questionAssetUrl,
          questionAssetType: this.questionAssetType,
          selectedLOs: this.selectedLOs,
          skill: this.skill,
          subject: this.subject,
          correctAnswerScore: this.correctAnswerScore,
          estimatedTime: this.estimatedTime,
          bloomsTaxonomy: this.bloomsTaxonomy,
          knowledgeLevel: this.knowledgeLevel,
          complexityLevel: this.complexityLevel,
          difficultyLevel: this.difficultyLevel,
          proficiencyLevel: this.proficiencyLevel,
          correctAnswer: this.getCorrectAnswer(),
          sanitizedQuestionStatement,
          level: this.level,
          hint: this.hint,
          tags: selectTags,
          answerExplanation: this.answerExplanation,
          questionOptions: this.getOptions(),
          topic: this.topic,
          strand: this.strand,
          subStrand: this.subStrand,
          grade: this.grade,
          // typeOfAudience: this.typeOfAudience,
          authoredBy: this.authoredBy,
          product: this.product,
          questionId: this.questionid,
          hrisUniqueRole: this.selectedHrisRoles,
          isCaseSensitive: this.isCaseSensitive === "Yes",
          languageOfQuestion: this.languageOfQuestion,
          groupName: this.groupName
        });
        //console.log(response.data.success);
        if (response.status == 200) {
          this.isCreatingQuestion = false;
          this.successDialog = true;
          this.createQuestionDialog = false;
          this.fetchQuestions();
        } else {
          this.isCreatingQuestion = false;
          this.errorDialog = true;
          this.errorMessage = response.data.error;
        }
      }
    },
    getTextWithoutHTMLTags(text) {
      // Strip HTML tags from the text
      return text.replace(/<[^>]*>?/gm, '');
    },
    editOption(index) {
      console.log("Edit Option Called");
      console.log("Checkpoint 1:", this.options);
      this.editedOptionIndex = index;
      this.optionTextFieldDialog = true;
      setTimeout(() => {
        this.editedCkeditorValue =
          this.options[this.editedOptionIndex].optionValue;

        // This code will execute after a 1 second delay
        //this.options[this.editedOptionIndex] = this.options[index];
      }, 1000);
      console.log("Checkpoint 2:", this.options);
    },

    async deleteQuestion(items) {
      await QuestionsController.deleteQuestion();
      //console.log(response.data);
    },
    clearFilter() {
      // this.appliedFilter = false;
      this.selectedQuestionTypeFilter = [];
      this.selectedLevelFilter = [];
      this.selectedGradeFilter = [];
      this.selecteddifficultyLevelFilter = [];
      this.selectedcomplexityLevelFilter = [];
      this.selectedknowlewdgeLevelFilter = [];
      this.selectedbloomsTaxonomyLevelFilter = [];
      this.selectedskillsFilter = [];
      this.selectedsubjectsFilter = [];
      this.selectedReportedQuestionsFilter = null;
      this.sendSelectedReportedQuestionsFilter = [];
      this.filterVal = "";
      this.customStartDate = null;
      this.customEndDate = null;
      // this.filterDialog = false;
      this.filterData = false;
      this.filterCount = 0;
      this.getNewQuestionsData()
      this.filterQuestions(
        this.selectedQuestionTypeFilter,
        this.selectedLevelFilter,
        this.selectedGradeFilter,
        this.selecteddifficultyLevelFilter,
        this.selectedcomplexityLevelFilter,
        this.selectedknowlewdgeLevelFilter,
        this.selectedbloomsTaxonomyLevelFilter,
        this.selectedskillsFilter,
        this.selectedsubjectsFilter,
        this.selectedReportedQuestionsFilter
      );
      // Add method to reset the combinations map when starting new validation
      this.resetQuestionIdValidation();
    },
    async filterQuestions(
      questionTypeIds,
      levelIds,
      gradeIds,
      diffLevelIds,
      complexityLevelIds,
      knowledgeLevelIds,
      bloomsTaxonomy,
      skillIds,
      subjectIds,
      reportQuestions
    ) {
      console.log("filter function call",reportQuestions,subjectIds);
      let questionIds = "";
      let levelId = "";
      let gradeId= "";
      let diffLevelId = "";
      let compLevelIds = "";
      let knowLevelIds = "";
      let bloomsLevelIds = "";
      let skillsId = "";
      let subIds = "";
      let reportedQIds = "";

      questionIds = questionTypeIds.join(" ");
      levelId = levelIds.join(" ");
      gradeId = gradeIds.join(" ");
      diffLevelId = diffLevelIds.join(" ");
      compLevelIds = complexityLevelIds.join(" ");
      knowLevelIds = knowledgeLevelIds.join(" ");
      bloomsLevelIds = bloomsTaxonomy.join(" ")
      skillsId = skillIds.join(" ");
      subIds = subjectIds.join(" ");
      reportedQIds = reportQuestions;
      
      this.filterVal =
        questionIds +
        levelId +
        gradeId +
        diffLevelId +
        compLevelIds +
        knowLevelIds +
        bloomsLevelIds +
        skillsId +
        subIds +
        reportedQIds;

      await this.algoliaIndex
        .search(this.filterVal, {
          hitsPerPage: this.pageSize,
          page: this.page - 1,
        })
        .then(({ hits }) => {
          console.log(hits);
          this.tableData = hits;
        });
      const { nbHits } = await this.algoliaIndex.search(this.filterVal, {
        hitsPerPage: 0,
      });
      this.count = nbHits;
      console.log("filter responce", this.filterVal);
      // this.filterDialog = false;
      this.filterData = true;
    },
    
    async getQuestionsFromAlgolia() {
    console.log("PageSize:", this.pageSize, " Page:", this.page);
    if (this.filterVal == "") {
        if (this.search == null) { // Check if `search` is null
            await this.algoliaIndex
                .search("", {
                    hitsPerPage: this.pageSize,
                    page: this.page - 1,
                })
                .then(({ hits }) => {
                    console.log(hits);
                    this.tableData = hits;
                });
        } else {
            await this.algoliaIndex
                .search(this.search, {
                    hitsPerPage: this.pageSize,
                    page: this.page - 1,
                })
                .then(({ hits }) => {
                    console.log(hits);
                    this.tableData = hits;
                });
        }
    } else {
        if (this.search == null) { // Check if `search` is null
            let values = this.filterVal;
            await this.algoliaIndex
                .search(values, {
                    hitsPerPage: this.pageSize,
                    page: this.page - 1,
                })
                .then(({ hits }) => {
                    console.log(hits);
                    this.tableData = hits;
                });
        } else {
            let values = this.filterVal + this.search;
            await this.algoliaIndex
                .search(values, {
                    hitsPerPage: this.pageSize,
                    page: this.page - 1,
                })
                .then(({ hits }) => {
                    console.log(hits);
                    this.tableData = hits;
                });
        }
    }
},

    getFilterCount() {

      if(!this.isCustomDate){
        this.getDateRange();
      }
      this.filterCount = 0;
      
      let combinedFilters = [this.selectedQuestionTypeFilter, this.selectedLevelFilter,  this.selectedGradeFilter,
        this.selecteddifficultyLevelFilter, this.selectedcomplexityLevelFilter,this.selectedknowlewdgeLevelFilter,
        this.selectedbloomsTaxonomyLevelFilter, this.selectedskillsFilter, this.selectedsubjectsFilter,this.sendSelectedReportedQuestionsFilter]
      this.filterCount = combinedFilters.reduce((accumulator, current)=> current.length>0 ? accumulator+1 : accumulator, 0)

      if (!this.isObjectEmpty(this.sendSelectedReportedQuestionsFilter)){
        this.filterCount++;
      }
      
      this.getNewQuestionsData();
    },    
    isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
    },
    csvFileToJSON(file) {
      var contents = [];
      this.bulkQuestionJsonData = [];
      this.bulkQuestionUploadResponse = [];
      this.removedQuestion = [];

      const requiredHeaders = [
        'product',
        'questionId',
        'skill',
        'level', 
        'grade',
        'subject',
        'strand',
        'subStrand', 
        'topic',
        'isCaseSensitive',
        'hrisUniqueRole',
        'questionType',
        'marksAllotted',
        'languageOfQuestion',
        'questionStatement',
        'correctAnswer',
        'difficultyLevel',
        'complexityLevel'
      ];

      try {
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: function(results) {
            this.content = results;
            this.parsed = true;
            contents = this.content.data;

            const headers = contents[0].map(header => header.replace('*', '').trim());

            const missingHeaders = requiredHeaders.filter(
              reqHeader => !headers.includes(reqHeader)
            );

            if (missingHeaders.length > 0) {
              const missingHead = "Following headers are missing:\n" + 
                    missingHeaders.join("\n") + "\n";
              this.downloadTextFile(missingHead, "uploadReport.txt");
              return;
            }

            var jsonData = [];
            var removedQuestion = [];
            var blankcell = false;
            for (var i = 1; i < contents.length; i++) {
              var rowData = {};
              var options = [];
              var cells = contents[i];
              // if (blankcell) {
              //   break;
              // }
              let questionType = null
              for (var j = 0; j < cells.length; j++) {

                if (cells[j].trim().length === 0) {
                  if (!removedQuestion.includes(i)) {
                    removedQuestion.push(i);
                    var text = "blank cell ";
                    // this.skippedQuestion(i, text);
                  }

                  continue;
                }

                var skillIndex = headers.indexOf("skill");

                if (!this.checkMatters(headers[j], cells[j], cells[skillIndex], headers, cells)) {
                  removedQuestion.includes(i) ? "" : removedQuestion.push(i);
                  this.skippedQuestion(i, headers[j]);
                  continue;
                }
                if (headers[j].includes("difficultyLevel")) {
                  rowData[headers[j]] = cells[j].toUpperCase();
                  continue;
                }

                if (headers[j].includes("complexityLevel")) {
                  rowData[headers[j]] = cells[j].toUpperCase();
                  continue;
                }

                if (headers[j].includes("grade")) {
                  if (cells[j].indexOf('Grade ') === -1 && cells[j] !== 'NA')
                    rowData[headers[j]] = `Grade ${cells[j]}`;
                  else {
                    rowData[headers[j]] = `${cells[j]}`;
                  }
                  continue;
                }
                var key = headers[j];

                key = "" + key;
                var value = cells[j].trim();
                // Check if the current cell is an option key
                if (key.startsWith("optionKey")) {
                  // Create a new option object and add it to options array
                  var option = { optionKey: value };
                  options.push(option);
                }
                // Check if the current cell is an option value or a Google Drive file URL
                else if (key.startsWith("optionValue") && value.includes("https://drive.google.com/file/")) {
                  // Extract the file ID from the Google Drive URL
                  var found = value.match(/\/d\/([A-Za-z0-9-]+)/);
                  if (found && found[1].length) {
                    // Modify the URL to fetch the file from Google Drive
                    value = "https://drive.google.com/uc?export=view&id=" + found[1];
                  }
                  // Find the index of the last option added to options array
                  var lastOptionIndex = options.length - 1;
                  if (lastOptionIndex >= 0) {
                    // Add the option value (either modified URL or original value) to the last option in options array
                    options[lastOptionIndex].optionValue = value;
                  }
                }
                // For regular option values, add them directly to the options array
                else if (key.startsWith("optionValue")) {
                  // Find the index of the last option added to options array
                  var lastOptionIndex = options.length - 1;
                  if (lastOptionIndex >= 0) {
                    // Add the option value to the last option in options array
                    options[lastOptionIndex].optionValue = value;
                  }
                } else {
                  rowData[key] = cells[j].trim();
                  if(key == 'subject'){
                    rowData[key] = cells[j].split(',').map(item=> item.trim())
                  }
                
                }

                // if (j == cells.length - 1) {
                if (headers[j]?.includes("questionType")) {
                  questionType = cells[j]
                }
                if(questionType === 'TRUE_FALSE') {
                  options = [
                    {
                      optionKey: "A",
                      optionValue: "TRUE",
                    },
                    {
                      optionKey: "B",
                      optionValue: "FALSE",
                    },
                  ]
                }
                rowData["questionOptions"] = options;
                // }
              }
              if (i != 0) {
                if (!removedQuestion?.includes(i)) {
                  rowData["sanitizedQuestionStatement"] = this.sanitizedQuestion(rowData["questionStatement"])
                  jsonData.push(rowData);
                }
              }
            }
            var msg = "Question No. ";
            removedQuestion.forEach((obj) => {
              msg = msg + obj + ",";
            });
            msg = msg + " Will not upload due to black cell or Wrong Entry";
            if (removedQuestion.length != 0) {
              console.log("removedQuestion",removedQuestion)
              var msg2 = this.removedQuestion.join("\n");
              // this.downloadTextFile(msg, "uploadReport.txt");
              this.downloadTextFile(msg2, "uploadReport.txt");
            }
            // console.log("skipped reson=>",this.removedQuestion.join(" ,"))
            console.log("json data", jsonData);
            this.bulkQuestionJsonData = jsonData;
            var index = 2;

            if (!blankcell) {
              this.bulkQuestionJsonData.forEach(async (question) => {
                try {
                  const response = await QuestionsController.createQuestion(
                    question
                  );
                  if (response.status == 200) {
                    console.log(question);
                    this.bulkQuestionUploadResponse.push({
                      questionIndex: index,
                      status: "Uploaded Successfully!",
                    });
                  } else {
                    this.bulkQuestionUploadResponse.push({
                      questionIndex: index,
                      status: "Uploaded Failed!",
                    });
                  }
                  index++;
                } catch (error) {
                  console.log(error);
                }
              });
            }
          }.bind(this),
        });
      } catch (e) {
        console.error(e);
      }
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      console.log(file);
      if (file) {
        this.csvFileToJSON(file);
        this.uploadingQuestionsDialog = true;
      }
    },
    resolveQuestionAssetUrl: async function () {
      const url = this.questionAssetUrl
      if(this.questionAssetUrlForPreview==IMAGE_ALT_SRC){
        if (url.startsWith('https://drive.google.com/')) {
          let fileId = null
          if(url.indexOf('&id=') > -1) {
            fileId = url.split('&id=').pop()
          } else {
            // Regular expression pattern to extract file ID
            const pattern = /\/file\/d\/([^\\/]+)\//;
            fileId = url.match(pattern)[1];
          }
          try {
            const response = await QuestionsController.doFetchFileContentFromDriveUrl(fileId)
            if(response.status == '500'){
              this.questionAssetUrlForPreview = IMAGE_ALT_SRC
            } else {
              this.questionAssetUrlForPreview = window.URL.createObjectURL(new Blob([response.data]));
            }
          } catch(err) {
            this.questionAssetUrlForPreview = IMAGE_ALT_SRC
            alert(this.questionAssetUrlForPreview)
            console.error('Error occured ', err)
          }
        } else if(url !== this.questionAssetUrlForPreview ) {
          this.questionAssetUrlForPreview = url
        }
      }
    },
    resolveQuestionOptionUrl: async function (url) {
      const optionIndex = this.options.findIndex(({ optionValue }) => optionValue === url)
      if (url.startsWith('https://drive.google.com/')) {
        let fileId = null
        if(url.indexOf('&id=') > -1) {
          fileId = url.split('&id=').pop()
        } else {
          // Regular expression pattern to extract file ID
          const pattern = /\/file\/d\/([^\\/]+)\//;
          fileId = url.match(pattern)[1];
        }
        const response = await QuestionsController.doFetchFileContentFromDriveUrl(fileId)
        this.options[optionIndex].optionValue = window.URL.createObjectURL(new Blob([response.data]));
      }
    },
    updateUrlParams() {
      const query = {};
      
      if (this.search) {
        query.search = this.search;
      }
      this.$router.replace({ 
        query: query 
      }).catch(() => {});
    },
    // Add method to reset the combinations map when starting new validation
    resetQuestionIdValidation() {
      this.questionIdLanguageCombos.clear();
    },
    validateQuestionId(value) {
      if (!this.product || !value) {
        this.questionIdError = "";
        return true;
      }

      const prefix = this.product; // Keep product name as-is
      const pattern = new RegExp(`^${prefix}_(\\d+|[a-zA-Z]\\d+)$`);

      if (!pattern.test(value)) {
        this.questionIdError = `Question ID must start with ${prefix}_`;
        return false;
      }
      
      this.questionIdError = "";
      return true;
    },

    sanitizedQuestion(statement) {
      const htmlEntityMap = {
    '&nbsp;': '',
    '&lt;': '<',
    '&gt;': '>',
    '&amp;': '&',
    '&quot;': '"',
    '&apos;': "'",
    '&cent;': '¢',
    '&pound;': '£',
    '&yen;': '¥',
    '&euro;': '€',
    '&copy;': '©',
    '&reg;': '®',
    '&times;': '×',
    '&divide;': '÷',
    '&plus;': '+',
    '&minus;': '−',
    '&equals;': '=',
    '&ne;': '≠',
    '&le;': '≤',
    '&ge;': '≥',
    '&harr;': '<->',
    '&#39;': "'",
    '&sup': '^',
    };
    const decodeHtmlEntities = (str) => {
    return str.replace(/&[a-zA-Z0-9#]+;/g, (entity) => htmlEntityMap[entity] || entity);
    };
    if (!statement) {
      console.log("statement",statement);
      return '';
    }

    // Replace MathML fractions with numerator/denominator notation
    statement = statement.replace(/<mfrac>\s*<mrow>\s*<mo>(.*?)<\/mo>\s*<mn>(.*?)<\/mn>\s*<\/mrow>\s*<mn>(.*?)<\/mn>\s*<\/mfrac>/g, '($1$2)/$3');
    statement = statement.replace(/<mfrac>\s*<mn>(.*?)<\/mn>\s*<mn>(.*?)<\/mn>\s*<\/mfrac>/g, '$1/$2');

    // Replace mathematical operators and clean up tags
    statement = statement
      .replace(/<mo>&#215;<\/mo>/g, '×') // Multiplication
      .replace(/<mo>&#160;<\/mo>/g, ' ') // Non-breaking space
      .replace(/<mo>(.*?)<\/mo>/g, '$1') // General operators
      .replace(/<mfenced>.*?<mrow>(.*?)<\/mrow>.*?<\/mfenced>/g, '($1)') // Fenced structures
      .replace(/<[^>]*>/g, '') // Remove all HTML tags
      .replace(/\n/g, ' ') // Remove newlines
      .replace(/&nbsp;/g, ' ') // Non-breaking spaces
      .trim(); // Trim whitespace

    // Decode HTML entities
    statement = decodeHtmlEntities(statement);

    return statement;
  },
  },
  created() {
    // this.getNewQuestionsData();
    this.getLO();
    this.getSubjects();
    this.getSkills();
    this.getLevels();
    this.getGrades();
    this.getAudienceRoles();
    this.debouncedSearch = debounce(this.getNewQuestionsData, 500)
  },
  unmounted() {
    this.debouncedSearch.cancel();
  },
  beforeRouteUpdate(to, from, next) {
    this.search = to.query.search || "";
    next();
  }
};
</script>
<style scoped>

.v-list-item__content {
  min-height: 64px;
}

.qpad {
  padding-top: 80px !important;
}

.qb-container {
  .mdi-delete,
  .mdi-format-textbox {
    font-size: 24px !important;
  }
}
</style>
