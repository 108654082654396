<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>
  <div class="interview-details-main-container" v-else>    
      <v-dialog
        v-model="recommandationFeedbackForm"
        persistent
        width="1000px"
        class="elevation-0"
      >
        <v-card class="pa-8">
          <div class="text-h6">Recommendation* </div>
          <v-row class="mt-2">
            <v-col cols="8">
              <v-row>
                <v-col cols="6"><span class="text-body-2">System Recommendation </span></v-col><v-col cols="6"><span
              class="ml-2 mt-2 text-caption cursor pa-2 rounded-xl text-body-2"
              :class="computedClass"
              medium
              >{{ finalRecommendation }}</span
            ></v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8">
              <v-row class="d-flex align-baseline">
                <v-col cols="6"><span class="text-body-2"> Interviewer recommendation </span></v-col><v-col cols="6">
                    <span>  
                      <v-select
                      :items="interviewerRecommendationOptions"
                      v-model="selectedinterviewerRecommendation"
                      label="Recommendation"
                      outlined
                      :rules="[rules.required]"
                     
                    ></v-select>
                </span>
                </v-col>
              </v-row>
            </v-col> <v-col cols="6"></v-col>
          </v-row>
         
          <div class="pt-6">Remarks* </div>
          <v-textarea
          counter
          solo
          v-model="remarkValues"
          :rules="[rules.required, rules.counter]"
          maxlength="1000"
          class="rounded-xl mt-2 border-button"
        ></v-textarea>
          <div class="d-flex pt-2">
            <v-checkbox v-model="isAgree"></v-checkbox>
            <div class="text-caption mt-2">
              By submitting this interview rating, I confirm that I have
              thoroughly evaluated the candidate's performance and provided a
              fair and accurate representation of their qualifications and
              suitability for the position. This rating will be considered as a
              part of the assessment process to inform the final decision on
              candidate selection.*
            </div>
          </div>
          <v-card-actions class="px-6 pt-6 pb-3">
            <v-spacer></v-spacer>
            <v-btn
              width="102px"
              height="48px"
              rounded
              outlined
              class="btn outline-btn pa-4"
              @click="recommandationFeedbackForm = false"
              >Cancel</v-btn
            >
            <v-btn
              width="102px"
              height="48px"
              rounded
              @click="submitInterviewFeedback()"
              :disabled="
              selectedinterviewerRecommendation == '' ||
                finalRecommendation == '' ||
                remarkValues == '' ||
                remarkValues.length > 1000 ||
                isAgree == false
              "
              class="btn primary-btn pa-4"
              :loading="isbtnLoading"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
      v-model="videoDialog"
      persistent
      width="70%"
      class="elevation-0"
      

    >
      <v-card color="background" class="pa-8">
        <div>
          <v-icon @click="dialogClosed" class="pa-0 ma-0">mdi-close</v-icon>
        </div>
        <div  class="d-flex justify-center text-h6 pb-2">
          Recorded Video
        </div>
        <div class="d-flex recordViedoCard justify-space-between w:100 align-center pa-2">
          <div>
              <div class="text-caption">RECOMMEMDED TOPIC</div>
              <div class="text-subtitle-1">{{this.currentVideo?.topicName}}</div>
          </div>
          <div>
              <div class="text-caption">RECOMMEMDED DURATION</div>
              <div  class="text-subtitle-1">{{getTime(this.currentVideo?.duration) ?? '12 -17 Mins'}} </div>
          </div>
      </div>
      <div class="userVidDivForDemo bg-white d-flex justify-center">
        <video :key="this.currentVideo?.videoUrl" ref="video" controls width="100%" class="pt-2" style="max-height: 50vh;">
          <source  :src="this.currentVideo?.videoUrl" type="video/mp4">
      </video>
      </div>
      
      </v-card>
    </v-dialog>
    <p class="pa-4"> <span class="text-subtitle-2">Interview Panel</span> <span><v-icon>mdi-chevron-down</v-icon></span></p>
      <div
        class="qpad ml-4 mr-6 my-0 w-100 d-flex align-center"
      >
        <div class="font-weight-bold">Interview Details</div>
        <div class="d-flex align-center ml-auto interview-btn-container">
          <v-btn v-if='step == 2 || step == 3':disabled="disableBack" @click="showRatingUpdateView" class="btn outline-btn">Back</v-btn>
          <v-btn
            v-if="step == 2"
            depressed
            :disabled="disableNext"
            @click="goToDemoComparison"
            rounded          
            class="btn primary-btn"
          >
            Next
          </v-btn> 
          <v-btn
            v-if="this.step == 3"
            depressed
            @click="openRecomendationFeedbackForm()"
            rounded          
            class="btn primary-btn"
          >
            Submit
          </v-btn>        
          <v-btn
            v-if="this.step == 1 && userData.interViewFeedback == undefined"
            depressed
            @click="startInterview"
            rounded           
            class="btn primary-btn"
          >
            Start Interview
          </v-btn>
        </div>
      </div>
      <v-container fluid>
        <div class="d-flex interview-details-inner-container">
          <aside          
            height="100%"
            elevation="0"
            color="#F7F7F3"
            class="pa-4 rounded-lg elevation-0 cardBorder"
          >
            <div>
              <div class="d-flex mb-5">
                <div class="user-icon__container mr-4">
                  <img v-if="userData.userinfo?.proctoringPic"
                    :src="userData.userinfo?.proctoringPic"
                    class="rounded-xl w-100"
                  />
                  <img v-else
                    src="../assets/avtar.png"
                    class="rounded-xl w-100"                    
                  />
                </div>
                <div>
                  <p role-type="subtitle-4">
                    {{ this.userData.userinfo?.personalInfo.firstName }}
                    {{ this.userData.userinfo?.personalInfo.lastName }}
                  </p>
                  <p role-type="subtitle-5">
                    {{ this.userData.userinfo?.personalInfo?.contactInfo?.email }}
                  </p>
                  <p role-type="subtitle-5">
                    {{
                      this.userData.userinfo?.personalInfo?.contactInfo
                        ?.phoneNumber
                    }}
                  </p>
                </div>
              </div>              
              <v-card class="pa-3 rounded-xl cardBorder interview-details-card">
             
                <div class="d-flex justify-space-between">
                  <div class="d-flex flex-column">
                    <p role-type="subtitle-5" class="mb-1">
                      Average Score
                    </p>
                    <p role-type="subtitle-4" class="font-weight-regular">
                      {{ this.avgScore }}/10
                    </p>
                  </div>
                  <div class="d-flex flex-column">
                    <p role-type="subtitle-5" class="mb-1">Status</p>
                    <p role-type="subtitle-4" class="font-weight-regular truncate" :class="{ 'text-green': interviewStatus == 'Completed', 'text-orange': interviewStatus == 'Ongoing' }">
                     {{interviewStatus}}
                    </p>
                  </div>
                </div>
              </v-card>              
              <div class="interview-details-card">
                <div class="mb-2">
                  <p role-type="subtitle-5" class="mb-1">Job Role</p>
                  <p role-type="subtitle-4" class="font-weight-regular truncate">Senior Secondary Teacher</p>
                </div>
                <div class="mb-2">
                  <p role-type="subtitle-5" class="mb-1">Level</p>
                  <p role-type="subtitle-4" class="font-weight-regular truncate"
                    v-for="data in this.userData.userinfo?.levels"
                    :key="data"
                    small
                    >{{ data }}
                  </p>                  
                </div>
                <div class="mb-2">
                  <p role-type="subtitle-5" class="mb-1">Board</p>
                  <p role-type="subtitle-4" class="font-weight-regular truncate">CISCE, CBSE</p>
                </div>
                <div class="mb-2">
                  <p role-type="subtitle-5" class="mb-1">Subject</p>
                  <div class="d-flex">
                    <p role-type="subtitle-4" class="d-flex font-weight-regular truncate"
                      v-for="data in this.userData?.userinfo?.subjects"
                      :key="data"
                      small
                      >
                      {{ data }},
                    </p>
                  </div>
                </div>
                <div>
                  <p role-type="subtitle-5" class="mb-1">Preferred Location</p>
                  <p role-type="subtitle-4" class="font-weight-regular truncate">Balewadi</p>
                </div>
              </div>
              <div class="interview-details-card">
                <div class="mb-2">
                  <p role-type="subtitle-5" class="mb-1">Total Experience</p>
                  <p role-type="subtitle-4" class="font-weight-regular">
                    {{ this.userData.candidateDetails.totalExperience.years }}.{{ this.userData.candidateDetails.totalExperience.months }} years
                  </p>
                </div>
                <div class="mb-2">
                  <p role-type="subtitle-5" class="mb-1">Relevant Experience</p>
                  <p role-type="subtitle-4" class="font-weight-regular">
                    {{ this.userData.candidateDetails.relevantExperience.years }}.{{ this.userData.candidateDetails.relevantExperience.months }} years
                  </p>
                </div>
                <div class="mb-2">
                  <p role-type="subtitle-5" class="mb-1">Notice Period</p>
                  <p role-type="subtitle-4" class="font-weight-regular">
                    {{ this.userData.candidateDetails.noticePeriod }} days
                  </p>
                </div>
              </div>
              <div class="d-flex justify-space-between interview-details-card">
                <div class="d-flex flex-column">
                  <p role-type="subtitle-5" class="mb-1">Current CTC</p>
                  <p role-type="subtitle-4" class="font-weight-regular">
                    {{ this.userData.candidateDetails.currentCTC }} LPA
                  </p>
                </div>
                <div class="d-flex flex-column">
                  <p role-type="subtitle-5" class="mb-1">Expected CTC</p>
                  <p role-type="subtitle-4" class="font-weight-regular">
                    {{ this.userData.candidateDetails.expectedCTC }} LPA
                  </p>
                </div>
              </div>
            </div>
          </aside>
          <section v-if="this.step == 3" class="pa-4 w-100 interview-rhs-container white">
            <div class="d-flex justify-end mb-3">              
              <v-btn-toggle
                v-model="videoToggleIndex"
                rounded
                @change="changeTheVideo()"
              >
                <v-btn   v-for="(subject, index) in subjects" :key="index">{{ subject.subjectName }}</v-btn>
              
              </v-btn-toggle>                            
            </div>
            <div class="video-play-section d-flex align-center pr-4 rounded-lg mb-4">
              <div class="d-flex align-center">
                <div class="video-image-wrapper">
                  <video :key="currentVideo.videoUrl" ref="video"  height="140px"
                        width="215px"
                        class="rounded-xl d-flex justify-align-center video-dimensions">
                      <source  :src="currentVideo.videoUrl" type="video/mp4">
                      </video>
                </div>
            
                <p role-type="subtitle-4" class="ml-12 font-weight-regular">{{ currentVideo.videoName }}</p>
              </div>
              <v-btn class="btn outline-btn ml-auto" @click="videoDialog = true">PLAY</v-btn>
            </div>
            <div class="">
              <div class="d-flex interview-score-container">
                <div class="interview-card">
                  <p role-type="body-3" class="font-weight-bold mb-4">System Score</p>                  
                  <v-card
                    width="100%"                    
                    elevation="0"                    
                    v-for="(data, index) in getSystemScore()"
                    :key="index"
                    class="rounded-xl elevation-0 cardBorder interview-inner-card gray-07 pa-4 mb-4"
                  >
                    <p class="d-flex justify-space-between" ><span role-type="body-4">{{ data.title }}</span> <span class="text-body-2">Socre - {{ data.rating }}/10</span></p>
                    <p role-type="subtitle-5" class="gray--text mb-4">{{ data.description }}</p>
                    <div class="d-flex align-center">
                      <div
                        v-for="rate in 10"
                        :key="rate"
                        :class="selectRating(rate, data.rating)"
                      >
                        {{ rate }}
                      </div>
                    </div>
                  </v-card>
                </div>
                <v-divider class="gray-03 mx-4" vertical></v-divider>
                <div class="interview-card">
                  <p role-type="body-3" class="font-weight-bold mb-4">Your Score</p>                  
                  <v-card
                    width="100%"                    
                    elevation="0"                    
                    v-for="(data, index) in this.inPersonDemoFeedback"
                    :key="index"
                    class="rounded-xl elevation-0 cardBorder interview-inner-card gray-07 pa-4 mb-4"
                  >
                    <p role-type="body-4">{{ data.title }}</p>
                    <p role-type="subtitle-5" class="gray--text mb-4">{{ data.description }}</p>
                    <div class="d-flex align-center">
                      <div
                        v-for="rate in 10"
                        :key="rate"
                        :class="selectRating(rate, data.rating)"
                      >
                        {{ rate }}
                      </div>
                    </div>
                  </v-card>
                </div>                
              </div>
            </div>
          </section>
          <v-card
            v-if="this.step == 1 && userData.interViewFeedback == undefined"
            color="#F7F7F3"          
            class="w-100 pa-6 mx-6 mt-2 white rounded-xl elevation-0 cardBorder"
          >
            <div class="py-2 pl-6 text-subtitle-1 font-weight-bold">Demo Video</div>
            <div class="d-flex">
              <v-card
                v-for="data in this.userData.demoVideoData"
                :key="data"
                color="#F7F7F3"
                width="240px"                
                class="rounded-xl elevation-0 mr-4"
              >                
                <div class="interview-demo-card">
                  <div>
                    <video :key="data?.videoUrl" ref="video"
                    width="100%"                   
                      class="rounded-xl d-flex justify-align-center">
                    <source  :src="data?.videoUrl" type="video/mp4">
                    </video>
                  </div>
                  <div class="text-caption pt-2 justify-align-center">
                    {{ data?.subject }}
                  </div>
                  <div class="text-body-2 justify-align-center topic-name">
                    {{ data?.topicName }}
                  </div>
                  <div class="d-flex justify-end mt-auto">
                    <v-btn
                      width="70px"
                      height="30px"
                      rounded
                      elevation="0"
                      @click="selectVideo(data)"
                      class="btn outline-btn view-btn gray-02 elevation-0 text-body-2"
                    >
                      View
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </div>
          </v-card>




          <v-card
            v-if="this.step == 2"
            color="#F7F7F3"
            width="100%"
            class="mx-6 mt-2 rounded-lg elevation-0"
          > 
          <div class="d-flex h-100">
            <InterviewScoreCard :readOnly="false" :interViewFeedbackData="interViewFeedback"  :tabsData="tabs" @onRatingChange="updateAvgScore($event)" @onRequestReady="updateRequestData($event)">

</InterviewScoreCard>
          </div>
          <!-- <p>undo this v-if changs</p> -->
         
          <div class="d-flex h-100" v-if="showInterviewDetails && false">
              <div class="d-flex flex-column">
                <div
                  v-for="(data, index) in skills"
                  :key="index"
                  @click="selectSkill(data.skillName, index)"
                  :class="['commonColoredContainer', selectedSkill != data.skillName
                      ? findColor(data.skillName)
                      : findColorForSelected(data.skillName)
                  ]"
                >
                  <span class="spanContainer text-subtitle-2">{{
                    data.skillName
                  }}</span>
                </div>
              </div>
              <v-col class="pt-6 pl-4">
                <div v-if="selectedSkill == 'Core Skills'">
                  <v-col class="py-0">
                    <v-btn-toggle
                      rounded
                      mandatory
                      dense
                      class="subjectToggel mb-4"
                    >
                      <v-btn
                        v-for="(data, index) in this.subject"
                        :key="index"
                        width="125px"
                        @change="selectSubject(index)"
                        :class="
                          selectedSubjectIndex == index
                            ? 'btnLiteColor btn-color--text'
                            : 'btn-color--text'
                        "
                      >
                        {{ data.subjectName }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-card
                    width="100%"
                    height="20%"
                    elevation="0"
                    color="#F7F7F3"
                    v-for="(data, index) in this.subject[
                      this.selectedSubjectIndex
                    ]?.feedback"
                    :key="index"
                    class="rounded-xl elevation-0 cardBorder px-3 py-3 mb-4"
                  >
                    <div class="text-body-1">{{ data.title }}</div>
                    <div
                      class="text-subtitle-2 textGreyColor--text mb-5"
                    >
                      {{ data.description }}
                    </div>
                    <div class="d-flex align-center">
                      <div
                        v-for="rate in 10"
                        :key="rate"
                        @click="selectRatingValue(rate, index)"
                        :class="selectRating(rate, data.rating)"
                      >
                        {{ rate }}
                      </div>
                    </div>
                  </v-card>
                </div>
                <div v-else id="myScroll" class="h-100">
                  <v-card
                    width="100%"
                    elevation="0"
                    color="#F7F7F3"
                    v-for="(data, index) in this.skills[this.selectedSkillIndex]
                      ?.feedback"
                    :key="index"
                    class="rounded-xl elevation-0 cardBorder px-3 py-3 mb-4"
                  >
                    <div class="text-body-1">{{ data.title }}</div>
                    <div
                      class="text-subtitle-2 textGreyColor--text mb-5"
                    >
                      {{ data.description }}
                    </div>
                    <div class="d-flex">
                      <div
                        v-for="rate in 10"
                        :key="rate"
                        @click="selectRatingValueForSkills(rate, index)"
                        :class="selectRating(rate, data.rating)"
                      >
                        {{ rate }}
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-col>
            </div>
          </v-card>
          <v-card
            v-if="userData.interViewFeedback != undefined && this.step == 1"            
            width="100%"
            class="pa-6 mt-2 mx-5 rounded-xl elevation-0 cardBorder"
          >
        
            <v-container class="mt-4">
              <v-row justify="center">
                <v-col cols="12" sm="5">
                  <img
                    src="../assets/interviewFeedbackSuccess.png"
                    class="rounded-xl d-flex justify-align-center"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" sm="4">
                  <div
                    class="text-subtitle-1 d-flex justify-align-center font-weight-bold"
                  >
                    Interview feedback Submitted
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <div class="pl-4 text-subtitle-1 font-weight-bold">Demo Video</div>
            <div class="d-flex">
              <v-card
                v-for="data in this.userData.demoVideoData"
                :key="data"
                color="#F7F7F3"
                width="240px"
                class="rounded-xl elevation-0 mr-4"
              >
                <div class="interview-demo-card">
                  <div>
                    <video :key="data?.videoUrl" ref="video"
                    width="100%"                                  
                    class="rounded-xl d-flex justify-align-center">
                    <source  :src="data?.videoUrl" type="video/mp4">
                    </video>
                  </div>
                  <div class="text-caption pt-2 justify-align-center">
                    {{ data?.subject }}
                  </div>
                  <div class="text-body-2 justify-align-center topic-name">
                    {{ data?.topicName }}
                  </div>
                  <div class="d-flex justify-end mt-auto">
                    <v-btn
                      width="70px"
                      height="30px"
                      rounded
                      elevation="0"
                      @click="selectVideo(data)"
                      class="btn outline-btn view-btn gray-02 elevation-0 text-body-2"
                    >
                      View
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </div>
          </v-card>
          <!-- </v-card> -->

        </div>
      </v-container>
  </div>
</template>
<script>
import CampaignController from "@/controllers/CampaignController";
import AuthService from "../services/AuthService";
import "../styles.css";
import InterviewScoreCard from "@/components/InterviewScoreCard.vue";
import { cloneDeep } from "lodash";
import { getTime } from "../helpers/utils.js"

export default {
  components: {InterviewScoreCard},
  name: "InterviewFeedbackForm",
  data() {
    return {
      toggle_exclusive: undefined,  
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 1000 || 'Maximum 1000 characters',
      },
      showInterviewDetails:false,
      preloader: true,
      selectedSkill: "Core Skills",
      isSumitButtonActive: false,
      isSubmitSubject: false,
      isSubmitSkills: false,
      selectedSkillIndex: 0,
      selectedSubjectIndex: 0,
      remarkValues: "",
      isAgree: false,
      finalRecommendation: "",
      avgScore: 0,
      skillAvg: 0,
      currentVideo:{},
      recommandationFeedbackForm: false,
      userData: {},
      isbtnLoading: false,
      recommendation: ["NOT RECOMMNDED", "HOLD", "STRONGLY RECOMMNDED"],
      isFeedbackSubmit: false,
      subAvg: 0,
      ratingValue: 0,
      pedagogyFeedback: [
        {
          title: "Creativity and Innovation*",
          description:
            "creativity and ability to generate innovative ideas or approaches within the subject domain",
          rating: 0,
        },
        {
          title: "Classroom Management*",
          description:
            "Inquire about the candidate's strategies for creating a positive and inclusive learning environment",
          rating: 0,
        },
        {
          title: "Student Engagement*",
          description:
            "Candidate's methods for promoting active student engagement and participation",
          rating: 0,
        },
        {
          title: "Differentiation*",
          description:
            "Addressing diverse needs and learning styles of the students",
          rating: 0,
        },
        {
          title: "Lesson Planning*",
          description:
            "Review the candidate's proficiency in developing comprehensive lesson plans that align with curriculum standards and learning objectives for the subject",
          rating: 0,
        },
      ],
      englishFeedback: [
        {
          title: "Verbal Communication*",
          description:
            "Evaluate the candidate's ability to articulate ideas clearly and effectively during verbal communication",
          rating: 0,
        },
        {
          title: "Clarity and Conciseness* ",
          description:
            "Assess the candidate's capacity to communicate information concisely and avoid ambiguity",
          rating: 0,
        },
        {
          title: "Active Listening*",
          description:
            "Inquire about the candidate's ability to actively listen to students, colleagues, and parents to understand their needs and concerns",
          rating: 0,
        },
        {
          title: "Nonverbal Communication*",
          description:
            "Discuss the candidate's awareness of and proficiency in using nonverbal cues to enhance communication i.e. Body Language, Expression, Facial Expression etc",
          rating: 0,
        },
        {
          title: "Empathy and Empathetic Communication*",
          description:
            "Assess the candidate's capacity to empathize with students, colleagues, and parents and communicate in a supportive and compassionate manner",
          rating: 0,
        },
        {
          title: "Collaborative Communication*",
          description:
            "Inquire about the candidate's ability to collaborate effectively with colleagues, administrators, and other stakeholders",
          rating: 0,
        },
      ],
      inPersonDemoFeedback:[{
        title: "Confidence*",
        description: " Assess the teacher's confidence and composure during the demonstration.",
        rating:0,
      },{
        title: "Behaviour*",
        description: "Assess how the teacher interacts, behaves, and engages with students.",
        rating:0 
      },{
        title: "Fluency*",
        description:"Assess the teacher's ability to communicate ideas clearly and smoothly.",
        rating:0
      },{
        title:"Knowledge*",
        description:"Evaluate the teacher's knowledge and accuracy in the subject.",
        rating:0
      }],
      computerFeedback: [
        {
          title: "Basic Computer Proficiency*",
          description:
            "Assess the candidate's familiarity with basic computer operations and software applications",
          rating: 0,
        },
        {
          title: "Educational Technology Tools*",
          description:
            "Inquire about the candidate's knowledge and experience with educational technology tools and platforms",
          rating: 0,
        },
        {
          title: "Digital Content Creation*",
          description:
            "Evaluate the candidate's ability to create digital content for instructional purposes, such as presentations, videos, and interactive multimedia resources",
          rating: 0,
        },
        {
          title: "Internet Research Skills",
          description:
            "Discuss the candidate's proficiency in conducting internet research and evaluating online resources",
          rating: 0,
        },
        {
          title: "Learning Management Systems (LMS)*",
          description:
            "Inquire about the candidate's experience with learning management systems for organizing course materials, assignments, and communication with students",
          rating: 0,
        },
        {
          title: "Data Management and Analysis*",
          description:
            "Assess the candidate's ability to collect, manage, and analyze data using digital tools and software",
          rating: 0,
        },
      ],
      psychometryFeedback: [
        {
          title: "Professional Ethics and Integrity*",
          description:
            "Assess the candidate's commitment to ethical standards and integrity in their interactions with students, colleagues, and parents",
          rating: 0,
        },
        {
          title: "Self-awareness*",
          description:
            "Ask the candidate about their process of self-reflection and how they assess their teaching practices",
          rating: 0,
        },
        {
          title: "Empathy and Understanding*",
          description:
            "Inquire about the candidate's ability to empathize with students perspectives and understand their individual needs and challenges",
          rating: 0,
        },
        {
          title: "Motivation and Enthusiasm*",
          description:
            "Assess the candidate's level of motivation and enthusiasm for teaching and their ability to inspire and engage students",
          rating: 0,
        },
        {
          title: "Interpersonal Skills*",
          description:
            "Assess the candidate's capacity to build positive relationships with students, colleagues, parents, and other stakeholders",
          rating: 0,
        },
        {
          title: "Emotional Intelligence*",
          description:
            "Inquire about the candidate's ability to recognize, understand, and manage their own emotions, as well as those of their students",
          rating: 0,
        },
      ],
      subject: [],
      skills: [],
      videoDialog:false,
      userId: "",
      slotId: "",
      timeSlotId: "",
      intervieweremail: "",
      campaignId: "",
      showDemoDetails:false,
      recomendationScoreMap: {
        40 : "Not Recommended",
        60  : "Below Standard",  
        75  : "Moderately Recommended",  
        90  : "Highly Recommended",
        101  : "Strongly Recommended"
      },
      tabs:{
        headers: [{name: 'Core Skills',   totalfeedBackcount: 0,
        ScoredFeedbackCount: 0}],
        data:[]
      },
      interViewFeedback:[],
      disableNextBtn:true,
      showSubmit: false,
      showNext: false,
      disableNext: true,
      disableBack:true,
      showDemoComparision: false,
      videoToggleIndex:0,
      showBack: false,
      step:1,
      interviewStatus: "Not Started",
      interviewerRecommendationOptions:[],
      selectedinterviewerRecommendation: '',
      getTime
    };
  },
  computed:{
    computedClass() {
    if (this.finalRecommendation === "Not Recommended") return "not-recommended";
    if (this.finalRecommendation === "Below Standard") return "below-standard";
    if (this.finalRecommendation === "Moderately Recommended") return "moderately-recommended";
    if (this.finalRecommendation === "Highly Recommended") return "highly-recommended";
    if (this.finalRecommendation === "Strongly Recommended") return "strongly-recommended";
    return ""; // Default: no class if the status doesn't match
  }
  },
  watch: {},
  mounted() {
    this.interviewerRecommendationOptions = Object.values(this.recomendationScoreMap)
    this.userId = this.$route.query.userId;
    this.slotId = this.$route.query.slotId;
    this.timeSlotId = this.$route.query.timeSlotId;
    this.intervieweremail = this.$route.query.intervieweremail;
    this.campaignId = this.$route.query.campaignId;
    this.getInterInfo(
      this.intervieweremail,
      this.userId,
      this.slotId,
      this.timeSlotId,
      this.campaignId
    );
    
  },
  methods: {
    changeTheVideo(){
      let subjectName = this.subjects[this.videoToggleIndex].subjectName
      this.currentVideo  = this.userData.demoVideoData.filter((item)=>item.subject == subjectName)[0]
    },
      goToDemoComparison(){
      this.showDemoComparision = true
      this.showSubmit = true
      this.showNext = false
      this.disableBack = false
      this.step = 3
    },
    startInterview() {
      this.showInterviewDetails = true;
      this.showSubmit = false
      this.showNext = true
      this.showBack = true
      this.step = 2
      this.interviewStatus = "Ongoing"

    },
    showRatingUpdateView(){
      this.showInterviewDetails = true;
      this.showSubmit = false
      this.showNext = true
      this.showDemoComparision = false
      this.disableBack = true
      this.step = 2
      this.interviewStatus = "Ongoing"
    },
    dialogClosed() {
      this.pauseVideo();
      this.videoDialog = false;
    },
    pauseVideo() {
      if (this.$refs.video) {
        this.$refs.video.pause();
      }
    },
    async getSubject() {
      let innerData =[]
      this.tabs.headers[0].totalfeedBackcount = this.userData.userinfo?.subjects?.length * 3
      this.userData.userinfo?.subjects?.forEach((sub) => {
        let arr =  [
            {
              title: "Fundamental Knowledge*",
              description:
                "Understanding of basic principles and concepts related to the subject matter",
              rating: 0,
            },
            {
              title: "Depth of Knowledge*",
              description:
                "Probe the depth of the candidate's understanding by asking questions that delve into advanced or specialized topics within the subject area ",
              rating: 0,
            },
            {
              title: "Application of knowledge* ",
              description:
                "Assess the candidate's ability to apply their theoretical understanding to practical situations or case studies",
              rating: 0,
            },
          ]

        innerData.push({subName: sub, data:arr})
        this.subject.push({
          subjectName: sub,
          feedback: [

            {
              title: "Fundamental Knowledge*",
              description:
                "Understanding of basic principles and concepts related to the subject matter",
              rating: 0,
            },
            {
              title: "Depth of Knowledge*",
              description:
                "Probe the depth of the candidate's understanding by asking questions that delve into advanced or specialized topics within the subject area ",
              rating: 0,
            },
            {
              title: "Application of knowledge* ",
              description:
                "Assess the candidate's ability to apply their theoretical understanding to practical situations or case studies",
              rating: 0,
            },
          ],
        });
      });
      this.tabs.data.push(innerData)
    },
    getSkills() {
    
      this.userData.userinfo?.skills?.forEach((sub) => {
        switch (sub) {
          case "Pedagogy":{
            this.tabs.data.push({subName: sub, data: this.pedagogyFeedback})
            this.tabs.headers.push({name: sub, totalfeedBackcount: this.pedagogyFeedback.length,
            ScoredFeedbackCount: 0})
            this.skills.push({
              skillName: sub,
              feedback: this.pedagogyFeedback,
            });
          }
       
            break;
          case "Digital Literacy":{
          this.tabs.data.push({subName: sub, data:this.computerFeedback})
            this.tabs.headers.push({name: sub, totalfeedBackcount: this.computerFeedback.length,
              ScoredFeedbackCount: 0})
            this.skills.push({
              skillName: sub,
              feedback: this.computerFeedback,
            });
          }
       
            break;
          case "Communication Skills":{
            this.tabs.data.push({subName: sub, data:this.englishFeedback})
            this.tabs.headers.push({name: sub, totalfeedBackcount: this.englishFeedback.length,
              ScoredFeedbackCount: 0})
            this.skills.push({
              skillName: sub,
              feedback: this.englishFeedback,
            })
          }
         
        ;
            break;
          case "Psychometry":{
            this.tabs.data.push({subName:sub, data: this.psychometryFeedback, })
            this.tabs.headers.push({name: sub, totalfeedBackcount: this.psychometryFeedback.length,
              ScoredFeedbackCount: 0})
              this.skills.push({
                skillName: sub,
                feedback: this.psychometryFeedback,
              });
          }
            break;
          case "Core Skills":
            this.skills.splice(0, 0, {
              skillName: sub,
            });
            break;
        }
      });
      this.tabs.data.push({subName: 'Inperson Demo', data:this.inPersonDemoFeedback})
            this.tabs.headers.push({name: 'Inperson Demo', totalfeedBackcount: this.inPersonDemoFeedback.length,
              ScoredFeedbackCount: 0})
    },
 
    getSystemScore(){
      let subjectName = this.subjects[this.videoToggleIndex].subjectName
      this.currentVideo  = this.userData.demoVideoData.filter((item)=>item.subject == subjectName)[0]
      let feedbackCopy = cloneDeep(this.inPersonDemoFeedback);
      let demoScores = this.userData.demoScores[this.userData.demoScores.findIndex((item)=> item.name == subjectName)]
      feedbackCopy[0].rating  = demoScores.confidenceLevel
      feedbackCopy[1].rating = demoScores.behaviorlLevel
      feedbackCopy[2].rating = demoScores.fluencyLevel
      feedbackCopy[3].rating = demoScores.knowledgeLevel
      return feedbackCopy;
    }, 

    async avgForSubject() {
      this.isSubmitSubject = true;
      console.log("avgForSubject");
      var subjectAvg = 0;
      this.subject?.forEach((sub) => {
        var currSubAvg = 0;
        sub?.feedback?.forEach((feed) => {
          currSubAvg = currSubAvg + feed.rating;
          if (feed.rating == 0) {
            this.isSubmitSubject = false;
          }
        });
        var currSubAvg = currSubAvg / sub?.feedback?.length;
        subjectAvg = subjectAvg + currSubAvg;
        if (this.isSubmitSkills && this.isSubmitSubject) {
          this.isSumitButtonActive = true;
        }
      });
      this.subAvg = subjectAvg / this.subject?.length;
      if(this.skills?.length > 1){
        this.avgScore = ((this.subAvg + this.skillAvg) / 2).toFixed(2);
        if (isNaN(this.avgScore)) {
          this.avgScore = 0.00;
        }
      }else{
        this.avgScore = this.subAvg.toFixed(2);
        if (isNaN(this.avgScore)) {
          this.avgScore = 0.00;
        }
      }
    },
    async avgForSkills() {
      this.isSubmitSkills = true;
      console.log("avgForSkillls");
      var skillAvg = 0;
      var length = 0;
      var isHaveCoreSkill = false;
      this.skills?.forEach((sub) => {
        if (sub.skillName != "Core Skills") {
          var currSkillAvg = 0;
          sub?.feedback?.forEach((feed) => {
            currSkillAvg = currSkillAvg + feed.rating;
            if (feed.rating == 0) {
              this.isSubmitSkills = false;
            }
          });
          var currSkillAvg = currSkillAvg / sub?.feedback?.length;
          skillAvg = skillAvg + currSkillAvg;
        } else {
          isHaveCoreSkill = true;
        }
      });
      if (isHaveCoreSkill) {
        if (this.isSubmitSkills && this.isSubmitSubject) {
          this.isSumitButtonActive = true;
        }
        length = this.skills?.length - 1;
        this.skillAvg = skillAvg / length;
        this.avgScore = ((this.subAvg + this.skillAvg) / 2).toFixed(2);
        if (isNaN(this.avgScore)) {
          this.avgScore = 0.00;
        }
      } else {
        this.isSumitButtonActive = this.isSubmitSkills;
        length = this.skills?.length;
        this.skillAvg = skillAvg / length;
        this.avgScore = (this.subAvg + this.skillAvg).toFixed(2);
        if (isNaN(this.avgScore)) {
          this.avgScore = 0.00;
        }
      }
    },
    updateAvgScore(avgScore){
      console.log(avgScore)
      this.avgScore = avgScore.toFixed(2)
    },
    selectSkill(skillName, index) {
      console.log("selectSkills");
      this.selectedSkill = skillName;
      this.selectedSkillIndex = index;
    },
    selectSubject(index) {
      console.log("selectSubject", index);
      this.selectedSubjectIndex = index;
    },
    selectRatingValue(val, index) {
      console.log("selectRatingValueSub");
      this.subject[this.selectedSubjectIndex].feedback[index].rating = val;
      this.avgForSubject();
    },
    selectRatingValueForSkills(val, index) {
      console.log("selectRatingValueForSkills");
      this.skills[this.selectedSkillIndex].feedback[index].rating = val;
      this.avgForSkills();
    },
    findColor(value) {
      var colors = "";
      switch (value) {
        case "Core Skills":
          return "containerCoreSkillLite cursor";
          break;
        case "Pedagogy":
          return "containerPedagigyLite cursor";
          break;
        case "Communication Skills":
          return "containerEnglishLite cursor";
          break;
        case "Digital Literacy":
          return "containerComputerLite cursor";
          break;
        case "Psychometry":
          return "containerPsychrometricLite cursor";
          break;
        default:
          return "containerPsychrometricLite cursor";
      }
    },
    selectRating(rating, ratingValue) {
      switch (rating) {
        case 1:
          return ratingValue >= 1
            ? "interviewRate1 white--text"
            : "interviewRate";
          break;
        case 2:
          return ratingValue >= 2
            ? "interviewRate2"
            : "interviewRate";
          break;
        case 3:
          return ratingValue >= 3
            ? "interviewRate3"
            : "interviewRate";
          break;
        case 4:
          return ratingValue >= 4
            ? "interviewRate4"
            : "interviewRate";
          break;
        case 5:
          return ratingValue >= 5
            ? "interviewRate5"
            : "interviewRate";
          break;
        case 6:
          return ratingValue >= 6
            ? "interviewRate6"
            : "interviewRate";
          break;
        case 7:
          return ratingValue >= 7
            ? "interviewRate7"
            : "interviewRate";
          break;
        case 8:
          return ratingValue >= 8
            ? "interviewRate8"
            : "interviewRate";
          break;
        case 9:
          return ratingValue >= 9
            ? "interviewRate9"
            : "interviewRate";
          break;
        case 10:
          return ratingValue >= 10
            ? "interviewRate10"
            : "interviewRate";
          break;
        default:
          return "interviewRate";
          break;
      }
    },
    findColorForSelected(value) {
      switch (value) {
        case "Core Skills":
          return "containerCoreSkillHard active-skill";
          break;
        case "Pedagogy":
          return "containerPedagigyHard active-skill";
          break;
        case "Communication Skills":
          return "containerEnglishHard active-skill";
          break;
        case "Digital Literacy":
          return "containerComputerHard active-skill";
          break;
        case "Psychometry":
          return "containerPsychrometricHard active-skill";
          break;
        default:
          return "containerPsychrometricHard active-skill";
      }
    },
    updateRequestData(data){
      this.disableNext = false;
      this.subjects =  data.subjects;
      this.skills = data.skills
    },
    async submitInterviewFeedback() {
      this.skills.splice(0, 0, {
              skillName: 'Core Skills',
            });
      this.isbtnLoading = true;
      var data = {
        subjects: this.subjects,
        skills: this.skills,
        avgScore: this.avgScore,
        recommendation: this.finalRecommendation,
        interviewerRecommendation: this.selectedinterviewerRecommendation,
        remark: this.remarkValues,
        interviewerEmail : this.intervieweremail,
      };
      this.step=1
      try {
        var res = await CampaignController.submitInterViewFeedback(
          this.intervieweremail,
          this.slotId,
          this.timeSlotId,
          data,
          this.userId,
          this.campaignId
        );
        if (res.data.flag) {
          this.showDemoComparision = false;
          this.showBack = false;
          this.showSubmit = false;
          this.interviewStatus = 'Completed'
          console.log("success");
          this.getInterInfo(
            this.intervieweremail,
            this.userId,
            this.slotId,
            this.timeSlotId,
            this.campaignId
          );
          this.isbtnLoading = false;
          this.isFeedbackSubmit = true;
          this.showDemoDetails = true;
          this.recommandationFeedbackForm = false;
          
        }
      } catch (error) {
        alert(error);
        this.isbtnLoading = false;
      }
    },
    async getInterInfo(email, userId, slotId, timeSlotId, camapaignId) {
      try {
        var data = await CampaignController.getInterViewUserInfo(
          email,
          slotId,
          timeSlotId,
          userId,
          camapaignId
        );
        if (data.data.flag) {
          this.userData = data.data.data;
          this.currentVideo = this.userData.demoVideoData.filter((item)=>{
            return item.subject == this.userData.userinfo?.subjects[0]
          })[0]
             this.getSubject();
    
           this.getSkills();
          await this.avgForSkills();
          await this.avgForSubject();
          this.preloader = false;
          if (this.userData.interViewFeedback == undefined) {
            this.isFeedbackSubmit = false;
          } else {
            this.isFeedbackSubmit = true;
            this.interviewStatus = 'Completed'
            this.avgScore = this.userData.interViewFeedback.avgScore
          }
        } else {
          alert(data.data.message);
        }
      } catch (error) {
        alert(error);
      }
    },
    logout() {
      AuthService.logout();
      // this.$router.push("/login");
    },
    openRecomendationFeedbackForm() {      
      let calculatedAvgPercentage = ((this.avgScore / 10) * 100).toFixed(2);
      for (const [_score, _status] of Object.entries(this.recomendationScoreMap)) {
        if(Number(calculatedAvgPercentage) < _score ) {
          this.finalRecommendation = _status
          break
        }
      } 
      this.recommandationFeedbackForm = true
    
    },
    selectVideo(videoData) {
        this.currentVideo = videoData;
        this.videoDialog = true;
    }
  },
  created() {
    this.logout();
  },
};
</script>
<style scoped>
.not-recommended{
  color: rgba(212, 0, 40, 1);
  background-color: rgba(212, 0, 40, 0.16);
}

.below-standard{
  background-color: rgba(251, 117, 0, 0.16);
  color: rgba(251, 117, 0, 1);
}
.moderately-recommended{
  background-color: rgba(250, 220, 0, 0.16);
  color: rgba(250, 220, 0, 1);

    }
.highly-recommended{
  color: #06C270;
  background-color: rgba(6, 194, 112, 0.16);
}
.strongly-recommended{
  color: #036b3e;
  background-color: rgba(6, 194, 112, 0.50);
}
  aside {
    min-width: 290px;
    background: var(--white);
  }
  .interview-details-main-container {
    .interview-details-card {
      padding: 12px;
      border: 1px solid var(--gray-03);
      border-radius: 8px;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;
      margin-bottom: 20px;
    }
    .interview-details-inner-container {
      gap: 16px;
    }
    
    .interview-rhs-container {
      .v-btn {
        padding: 0 24px !important;
        color: var(--primary);
        background: var(--gray-02);
        border: 1px solid var(--blue-01) !important;
      }
      .v-btn--active {
        color: var(--white);
        background: var(--blue-01);
      }  
    }
  }

.v-list-item__content {
  min-height: 64px;
}

.qpad {
  padding-right: 40px !important;
}
.video-dimensions{
  height: 67px;
  width: 144px;
}
.video-play-section {
  border: 1px solid var(--gray-03);
}
.video-image-wrapper {
  width: 100px;
  height: 68px;
  img {
    width: 100%;
    height: 100%;
  }
}
.interview-score-container {
  .interview-card {
    flex: 1;
  }
  .interview-inner-card {
    background: var(--gray-07);
  }
}
.interview-btn-container {
  gap: 12px;
}

.text-green{
 color: #228B22;
}
.text-orange{
  color: #E49F0A;
}
.interview-demo-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid var(--gray-03);
  min-height: 280px;
  .view-btn {
    border: 1px solid var(--blue-01) !important;
    margin-top: auto;
  }
  .topic-name {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
}
</style>
