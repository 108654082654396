<template>
          <section class="bottomDiv flex-column" :class="[readOnly ? 'interview-details-main-container': 'interview-report-main-container']">
            <v-tabs v-model="activeTab" class="mb-4">
        <v-tab v-for="(tab, index) in tabsData.headers" :key="index" @change="selectSkill(tab.name)">
          {{ tab.name }} <span v-if="!readOnly">&nbsp; {{ tab.ScoredFeedbackCount }}/{{ tab.totalfeedBackcount }}</span>
        </v-tab>
      </v-tabs>

      <!-- Tab Items -->
      <!-- <v-tabs-items v-model="activeTab">
        <v-tab-item v-for="(tab, index) in tabHeaders" :key="index">
          <v-card flat>
            <v-card-text>
              {{ tab.ScoredFeedbackCount }}/{{ tab.totalfeedBackcount }}
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items> -->

        <div class="interview-panels" v-if="readOnly">
            <v-container fluid class="pa-0">
                <div class="w-100 d-flex">
                    <div class="flex-1">
                        <span class="text-body-2 font-weight-bold pa-4" v-if="readOnly">Candidate Assessment</span>
                    </div>
                    <div class="d-flex flex-1 justify-space-around">
                        <div class="d-flex">
                            <div class="interviewer-name" v-if="colVisiblity[2]">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                                            {{interViewFeedbackData[2].interviewerEmail ? interViewFeedbackData[2].interviewerEmail : '---' }}
                                        
                                        </span>
                                    </template>
                                    <div>
                                        {{interViewFeedbackData[2].interviewerEmail ? interViewFeedbackData[2].interviewerEmail : '---' }}
                                    </div>
                                </v-tooltip>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="interviewer-name" v-if="colVisiblity[1]">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        {{interViewFeedbackData[1].interviewerEmail ? interViewFeedbackData[1].interviewerEmail : '---' }}
                                    
                                    </span>
                                    </template>
                                    <div>
                                        {{interViewFeedbackData[1].interviewerEmail ? interViewFeedbackData[1].interviewerEmail : '---' }}
                                    </div>
                                </v-tooltip>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="interviewer-name" v-if="colVisiblity[0]">
                                <v-tooltip top >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                                            {{interViewFeedbackData[0].interviewerEmail ? interViewFeedbackData[0].interviewerEmail : '---' }}
                                        
                                        </span>
                                    </template>
                                    <div>
                                        {{interViewFeedbackData[0].interviewerEmail ? interViewFeedbackData[0].interviewerEmail : '---' }}
                                    </div>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </div>
        
        <div class="larger-bottom" :class="{ 'read-larger-bottom': readOnly, 'write-larger-bottom': !readOnly }">
            <div class="central-table">
                <div class="main-table rounded-xl">           
                        <div class="data-grid interview-report-data-grid">
                            <div v-if="activeTab == 0" class="button-toggle-row mb-4">
                                <div>
                                        <v-btn-toggle class="d-flex " :class="{'justify-start':readOnly, 'justify-end': !readOnly}"
                                        v-if="tabsData.data[0].length > 1"
                                        v-model="toggleType"
                                        mandatory
                                        dense
                                        rounded
                                    >
                                        <v-btn
                                        v-for="(skillBtn, index) in tabsData.data[0]"
                                        class="w-100 skill-btn"
                                        :class="
                                        toggleType == index
                                            ? 'blue white--text caption'
                                            : 'gray-02 caption'
                                        " :key="skillBtn.subName"
                                        >
                                        {{ skillBtn.subName }}
                                        </v-btn>
                                    </v-btn-toggle>
                                    <div class="d-flex" :class="{'justify-start': readOnly, 'justify-end': !readOnly}">
                                        <v-btn
                                            elevation="2"
                                            outlined
                                            rounded
                                            class="btn outline-btn pa-4" v-if="tabsData.data[0].length == 1" >
                                            {{tabsData.data[0][0].subName }}
                                        </v-btn>
                                        <div :class="[colColors[2]]"></div>
                                    </div>
                                </div>                            
                            </div>
                                <div class="scroll-this">
                                    <v-container fluid class="pa-0">
                                        <div v-for="(data, index) in displayData" class="mx-h-81" :key="data.title" :class="{ 'first-row': index === 0 && selectedSkill !== 'Core Skills' }" >
                                            <div class="d-flex">
                                                <v-card
                                                :class="readOnly ? 'assessment-instructions w-50 mb-4' : 'mb-6 w-100 rounded-xl elevation-0 cardBorder px-3 py-3 mb-0'">
                                                    <p  class="feedback-title pl-0">{{data.title}}</p>
                                                    <p class="feedback-desc pl-0 mb-4">{{data.description}}</p>
                                                    <div class="d-flex" v-if="!readOnly">
                                                    <div
                                                        v-for="rate in 10"
                                                        :key="rate"
                                                        @click="selectRatingValueForSkills(rate, index)"
                                                        :class="selectRating(rate, data.rating)"
                                                    >
                                                        {{ rate }}
                                                    </div>
                                                    </div>
                                                </v-card>                                            
                                            <div v-if="readOnly" class="feedback-title interview-ratings" :class="[ colColors[2]]"><span v-if="colVisiblity[2]">{{data.rating2 > 9 ? data.rating2: `0${data.rating2}` }} / 10</span></div>
                                            <div v-if="readOnly" class="feedback-title interview-ratings " :class="[ colColors[1], (colVisiblity[1] ? 'interviewer-bg': '') ] "><span v-if="colVisiblity[1]">                                                
                                                {{data.rating1  > 9 ? data.rating1: `0${data.rating1}` }} / 10</span></div>
                                            <div v-if="readOnly" class="feedback-title interview-ratings"  :class="[ colColors[0]]"><span v-if="colVisiblity[0]">{{data.rating  > 9 ? data.rating: `0${data.rating}` }} / 10</span></div>
                                        </div>
                                        </div>
                                    </v-container>
                                </div>
                   
                                <v-container fluid class="pa-0">
                                    <div class="d-flex last-row mb-10px" align-end v-if="readOnly">
                                        <div class="average-text w-50 d-flex justify-end align-center font-weight-bold pr-12">
                                            {{ subjectAverageRatings.name  }} Average
                                        </div>
                                        <div v-if="readOnly" class="feedback-title interview-average-ratings average-text"  :class="[ colColors[2]]"><span v-if="colVisiblity[2]">{{subjectAverageRatings.avg.avg2 | tofixRating}}</span></div>
                                        <div v-if="readOnly" class="feedback-title interview-average-ratings average-text" :class="[ colColors[1], (colVisiblity[1] ? 'interviewer-bg' : '')]"><span v-if="colVisiblity[1]">
                                            {{ subjectAverageRatings.avg.avg1 | tofixRating}}</span></div>
                                        <div v-if="readOnly" class="feedback-title interview-average-ratings average-text" :class="[ colColors[0]]"><span v-if="colVisiblity[0]">{{ subjectAverageRatings.avg.avg0 | tofixRating}}</span></div>
                                    </div>
                                </v-container>
                        </div>
                </div>
            </div>
        </div>
             <div class="comment-section" v-if="readOnly">
                <v-container fluid class="pa-0">
                    <div class="d-flex comment-container">
                        <div class="d-flex justify-end align-center w-50 pr-12">
                            <p>Comments</p>
                        </div>
                        <div class="flex-1" :class="[ colColors[2]]" @click="dialogHandler(interViewFeedbackData[2])">
                            <div class="text-right interviewer-name pa-2 rounded-lg cursor"  v-if="colVisiblity[2]" >
                                <p :class="interViewFeedbackData[2]?.recommendation == 'HOLD' ? 'hold-txt': 'recommend-txt'" >{{interViewFeedbackData[2].recommendation}}</p>
                                <p  class="interview-remark">{{interViewFeedbackData[2].remark | strippedContent}}</p> 
                            </div>
                        </div>
                        <div class="flex-1" :class="[ colColors[1], (colVisiblity[1] ? 'interviewer-bg' : '')]" @click="dialogHandler(interViewFeedbackData[1])">
                            <div class="text-right interviewer-name pa-2 rounded-lg cursor interviewer-bg"  v-if="colVisiblity[1]" >
                                <p :class="interViewFeedbackData[1]?.recommendation == 'HOLD' ? 'hold-txt': 'recommend-txt'" >{{interViewFeedbackData[1].recommendation}}</p>
                                <p  class="interview-remark">{{interViewFeedbackData[1].remark | strippedContent}}</p> 
                            </div>
                        </div>
                        <div class="flex-1" :class="[ colColors[0]]" @click="dialogHandler(interViewFeedbackData[0])">
                            <div class="text-right interviewer-name pa-2 rounded-lg cursor" v-if="colVisiblity[0]" >
                                <p :class="interViewFeedbackData[0]?.recommendation == 'HOLD' ? 'hold-txt': 'recommend-txt'" >{{interViewFeedbackData[0].recommendation}}</p>
                                <p  class="interview-remark">{{interViewFeedbackData[0].remark | strippedContent}}</p> 
                            </div>
                        </div>
                    </div>
                </v-container>   
            <div v-if="dialogActive">
                <v-dialog v-model="dialogActive"  max-width="366px">
                <v-card fluid  min-height="250px">
                <v-container fluid class="pa-0">
                    <v-card-text class="text-center">
                        <v-icon size="24" class="icon-aligner" @click="dialogHandler">mdi-close</v-icon>
                        <div class="d-flex flex-column justify-space-between">
                            <p class="text-h5 pt-4 pb-4 font-weight-bold">{{dialogContent.recommendation}}</p>
                        <p class="text-disabled grey--text text-subtitle-1 remark-color">
                        {{dialogContent.remark | strippedContent}}
                    </p>
            </div>
    </v-card-text>
  </v-container>
</v-card>
</v-dialog>
</div>             </div>
</section>

</template>
<script>

export default{
    name: 'InterviewScoreCard',
    props: {
        readOnly: {
            type: Boolean,
            default: false
        },
        interViewFeedbackData: {
            type: Array,
            required: false 
        },
        tabsData: {
            type: Object,
            required: true 
        }
    },
    computed: {
        displayData() {
            if (this.activeTab == 0) {
                return this.tabsData.data[0][this.toggleType].data
            } else {
                return this.tabsData.data[this.activeTab].data
            }
        },
        subjectAverageRatings() {
            if (this.activeTab == 0) {
                return {name: this.coreSkillToggle[this.toggleType], avg: this.avgMap['coreSkill'+this.coreSkillToggle[this.toggleType]] };
            } else {   
                return {name:this.selectedSkill, avg: this.avgMap['skill'+this.selectedSkill]};
                }
        }, 
    },
    data(){
       return {
        activeTab:0,
        avgMap: {},
        coreSkillToggle: [],
        coreSkilks: [],
        colVisiblity: [false, false, false],
        colColors: ['', '',''], 
        dialogActive: false,
        dialogContent: {},
        resultMap: {},
        selectedSkill: 'Core Skills',
        toggleType: 0,
        tabs: [
        { title: "Home", content: "Welcome to the Home tab" },
        { title: "Profile", content: "Here's your profile information" },
        { title: "Settings", content: "Adjust your settings here" },
      ],
        skills: null,
        selectedSkillIndex: 0,
        countofRating: 0,
       } 
  },
  mounted(){
    if(this.readOnly){
    this.createtableData(this.interViewFeedbackData)
    this.createAvg(this.resultMap)
    
    this.coreSkillToggle = this.interViewFeedbackData[0].subjects.map(item => item.subjectName)
    this.getColsVisiblity()
    this.getColColors()
    }else{
        this.countofRating = this.countOfRatign()
    }

  },
    filters: {
        strippedContent: function(string) {
            return string.replace(/<\/?[^>]+>/ig, " "); 
            },
        tofixRating: function(number){
            return number % 1 !== 0 || !Number.isInteger(number) ? number.toFixed(1) : number
        }
    },
  methods:{
    getCoreSkillFeedbackCount(){
        return this.interViewFeedbackData[0].subjects.reduce((acc, curvl)=>{
            return acc+curvl.feedback.length
        }, 0)
    },
    selectRatingValueForSkills(val, index) {
        if(this.activeTab == 0){
            this.tabsData.data[this.activeTab][this.toggleType].data[index].rating = val
           this.tabsData.headers[this.activeTab].ScoredFeedbackCount = this.countCoreSkillRatings(this.tabsData.data[this.activeTab])
        
        } else{
            this.tabsData.data[this.activeTab].data[index].rating = val;
            this.tabsData.headers[this.activeTab].ScoredFeedbackCount = this.countNonCoreSkillRating(this.tabsData.data[this.activeTab].data)

        }
         this.$emit("onRatingChange",this.calcualteAvgRating())
         this.nextBtnDisableCheck()
    //   this.skills[this.selectedSkillIndex].feedback[index].rating = val;
    },
    gotoDemoComparision(){
        this.formatReqData()
    },
    formatReqData(){
        let req = {subjects: [], skills:[]}
        this.tabsData.data.forEach((item, index) =>{
            if(index == 0){
                item.forEach((coreSub)=>{
                    req.subjects.push({subjectName: coreSub.subName, feedback: coreSub.data})
                })   
            }else{
                req.skills.push({skillName: item.subName, feedback: item.data})
            }
        })
        this.$emit('onRequestReady', req)
    },
    calcualteAvgRating(){
        return this.sumOfRating() /this.countofRating
    },
    nextBtnDisableCheck(){
        if(this.countofRating == this.ratingFilledCount()){

            this.gotoDemoComparision()
        } 
    },
    ratingFilledCount() {
    return this.tabsData.data.reduce((total, subject, index) => {
        let subjectCount = 0;
        
        if (index === 0) {
            subjectCount = subject.reduce((subtotal, coreSubject) => {
                return subtotal + coreSubject.data.reduce((ratingTotal, feedbackObj) => {
                    return ratingTotal + ((feedbackObj.title.includes('*') && feedbackObj.rating > 0) ? 1 : 0);
                }, 0);
            }, 0);
        } else {
            subjectCount = subject.data.reduce((ratingTotal, feedbackObj) => {
                return ratingTotal + ((feedbackObj.title.includes('*') && feedbackObj.rating > 0) ? 1 : 0);
            }, 0);
        }
        
        return total + subjectCount;
    }, 0);
},
    countMandatoryfeedback(data){
        return data.reduce((acc, cur)=>{
            return acc + (cur.title.includes('*') ? 1 : 0)
        },0)
    },
    countOfRatign(){
        return this.tabsData.data.reduce((total, subject, index)=>{
            let subjectCount = 0
            if(index == 0){
               subjectCount = subject.reduce((subtoal, coreSubject)=>{
                    return subtoal +  this.countMandatoryfeedback(coreSubject.data)
               },0)
            } else {
                subjectCount = this.countMandatoryfeedback(subject.data)
            }
            return total + subjectCount
        }, 0)
    },
    sumOfRating(){
        return this.tabsData.data.reduce((total, subject, index)=>{
            let subjectCount = 0
            if(index ==0){
                subject.forEach((coreSubject)=>{
                    subjectCount += coreSubject.data.reduce((count, item) => {
                    return  count + (item.title.includes('*') ? item.rating : 0)
                    }, 0);  
                })
       
         } else{
            subjectCount = subject.data.reduce((count, item)=>{
                return  count + (item.title.includes('*') ? item.rating : 0)
            }, 0)
         }
         return total + subjectCount
        }, 0)
    },
    countNonCoreSkillRating(data){
        return data.reduce((count, item) => {
            return item.rating > 0 ? count + 1 : count;
            }, 0);
    },
     countCoreSkillRatings(data){
        return data.reduce((total, subject) => {
            const subjectCount = subject.data.reduce((count, item) => {
            return item.rating > 0 ? count + 1 : count;
            }, 0);
            
            return total + subjectCount;
        }, 0);
    },
    createAvg(data) {
        for (let key in data) {
            this.getAvgRating(key, data[key])    
            }
    },
    createtableData(interveiwData) {
        interveiwData.forEach((item, index) => {
                item.subjects.forEach((coreSubject) => {
                if (index == 0) {
                    this.resultMap['coreSkill' + coreSubject.subjectName] = coreSubject.feedback;
                } else {
                    this.pluckRating('coreSkill'+coreSubject.subjectName, coreSubject.feedback, index)
                }
            })  

            item.skills.forEach((skill) => {
                if (index == 0 && skill.skillName !== 'Core Skills') {
                    this.resultMap['skill' + skill.skillName] = skill.feedback;
                } else if(skill.skillName !== 'Core Skills'){
                    this.pluckRating('skill' + skill.skillName, skill.feedback, index)
                }
            })
            
        })

    },
    dialogHandler(data = '') {
        this.dialogActive = !this.dialogActive;
        this.dialogContent = data
    },
    pluckRating(key, feedback, outerIndex) {
        feedback.forEach((item, index) => {
            this.resultMap[key][index]['rating'+outerIndex] = item.rating
        })
    },

        getAvgRating(key, data) {
        let avg ={
            ratingSum:0,
            ratingCount:0,
            rating1Sum:0,
            rating1Count:0,
            rating2Sum:0,
            rating2Count:0
        }
          data.forEach((item)=>{
            let nullArr = [undefined, null, '', NaN]
            if(item.title.includes('*')){
                if(nullArr.every((nullval)=> item.rating !== nullval)){
                    avg.ratingSum += item.rating;
                    avg.ratingCount++
                }
                    if(nullArr.every((nullval)=> item.rating1 !== nullval)){
                    avg.rating1Sum += item.rating1;
                    avg.rating1Count++
                }
                    if(nullArr.every((nullval)=> item.rating2 !== nullval)){
                    avg.rating2Sum += item.rating2;
                    avg.rating2Count++
                }
            }
          
          })
          this.avgMap[key] = {avg0: avg.ratingSum/avg.ratingCount, avg1:avg.rating1Sum/avg.rating1Count, avg2:avg.rating2Sum/avg.rating2Count }
    },
    getColsVisiblity() {
        this.interViewFeedbackData.forEach((item, index) => {
            this.colVisiblity[index] = true
        })
    },
    getColColors() {
            this.interViewFeedbackData.forEach((item, index) => {
                    this.colColors[index] = item.recommendation == 'HOLD' ? 'hold-bg' : 'recommend-bg'
            })
        },
    selectSkill(skillName) {
      this.selectedSkill = skillName;
    },
    selectRating(rating, ratingValue) {
      switch (rating) {
        case 1:
          return ratingValue >= 1
            ? "interviewRate1 white--text"
            : "interviewRate";
          break;
        case 2:
          return ratingValue >= 2
            ? "interviewRate2"
            : "interviewRate";
          break;
        case 3:
          return ratingValue >= 3
            ? "interviewRate3"
            : "interviewRate";
          break;
        case 4:
          return ratingValue >= 4
            ? "interviewRate4"
            : "interviewRate";
          break;
        case 5:
          return ratingValue >= 5
            ? "interviewRate5"
            : "interviewRate";
          break;
        case 6:
          return ratingValue >= 6
            ? "interviewRate6"
            : "interviewRate";
          break;
        case 7:
          return ratingValue >= 7
            ? "interviewRate7"
            : "interviewRate";
          break;
        case 8:
          return ratingValue >= 8
            ? "interviewRate8"
            : "interviewRate";
          break;
        case 9:
          return ratingValue >= 9
            ? "interviewRate9"
            : "interviewRate";
          break;
        case 10:
          return ratingValue >= 10
            ? "interviewRate10"
            : "interviewRate";
          break;
        default:
          return "interviewRate";
          break;
      }
    },
  }
}
</script>
<style scoped>
.row{
    flex: 0 0 auto;
}

.interviewer-name {
    max-width: 195px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.vertical-menu{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: hidden;
}
.mb-10px{
    margin-bottom:0px
}
.mx-h-81{
    max-height: 120px;
    /* display: flex;
    align-items: center */
}
.icon-aligner{
    margin-left: 90%; 
    margin-top: 2%;
}
.m-left-5{
   margin-right: 5px;
}
.m-left-10{
    margin-left: 10px;
}
.button-toggle-row{
  margin-top: 0px;  
}
.remark-color{
    max-height: 12vh;
    overflow: auto;
    color: #666666;
}
.first-row{
    margin-top: 0px;
}
.bottomDiv{
  margin: 10px 12px 4px 32px;

}
.data-grid {
    display: flex;
    flex-direction: column;   
    background-color: #FFFFFF;
}
.last-row {
  flex-grow: 1;
}
.recommend-txt{
    color:#4D695B;
    text-align: center;
}
.hold-txt{
    color:#B7842C;
    text-align: left;
}
.hold-bg{
    background-color: rgba(233, 182, 94, 0.24);
}
.recommend-bg{
    background-color: rgba(95, 159, 104, 0.24);
}
.feedback-title{
    font-size: 16px;
    font-weight: 600;    
    span {
        font-weight: normal;
    }
    &.interview-average-ratings {
        padding: 20px 0;
        span {
            font-size: 16px;
            font-weight: 600;
        }
    }
}
.feedback-desc{
    font-size: 12px;
    color:rgba(0, 0, 0, 0.6);
    padding-left: 10px;
}
.interviewer-name, .recommend-txt, .hold-txt{
    font-size: 14px;
}
.average-text{
    font-size: 14px;
}
.interview-remark{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Adjust the number of lines for your needs */
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
}
.containerPsychrometricHard, .containerPsychrometricLite, .containerComputerHard, .containerComputerLite, .containerEnglishHard, .containerEnglishLite, .containerCoreSkillLite ,.containerCoreSkillHard,.containerPedagigyHard,  .containerPedagigyLite{
    height: 100px;
    padding: 5px;
}
.main-table{
    display: flex;
}
.central-table{
    margin-top: 10px;    
    display: flex;
    .data-grid.interview-report-data-grid {
        padding: 0 0 0 16px;
    }
}
.gap-16 {
    gap: 16px;
}
.border-right {
    border-right: 1px solid #0000001F !important;
}

.score-container {
    background: #06C2702B;
    border: 1px solid #06C270;
    border-radius: 8px;
}

.w-90 {
    width: 90%
}

.col-2 {
    flex: 0 0 12.666667%;
    max-width: 12.6666666667%;
}
.scroll-this{
    overflow: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}

.larger-bottom{
   
    position: relative;    
    
}
.read-larger-bottom{    
}
.write-larger-bottom{
    width: 100%;
}
.larger-bottom::-webkit-scrollbar {
    display: none;
}

.m-width-none {
    max-width: none;
}
.skill-btn {
    width: 200px;
}

.interview-details-main-container {
    width: auto;
    padding-bottom: 30px;
    background: var(--white);
    .central-table, .main-table, .data-grid {
        width: 100%;
    }
    .mx-h-81 {
        max-height: 100%;
    }
    .v-tab {
        flex: 1;
    }
}

.interview-report-main-container {
    width: 100%;
    background: var(--white);
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
    .data-grid {
        width: 100%;
        padding: 0 16px;
    }
    .central-table, .main-table {
        width: 100%;
    }
    .mx-h-81 {
        max-height: 100%;
    }
    .data-grid.interview-report-data-grid {
        padding: 0 16px;   
    }
}
.interview-report-main-container {
    padding-bottom: 30px;
}
.interview-ratings, .interview-average-ratings {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.assessment-instructions {
    padding-right: 50px;
}
.comment-container {
    padding: 20px 0;
    gap: 10px;
    background: var(--gray-06);
}
.interviewer-bg {
    background: linear-gradient(0deg, rgba(233, 182, 94, 0.24) 0%, rgba(233, 182, 94, 0.24) 100%), #FFF;
}
.interview-details-main-container {
    &.interview-report-main-container {
        width: auto;
    }
}

</style>
