<template>
  <div>
    <v-card height="56px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="pl-0 ml-0">
        <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
          Masters
        </p>
        <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Clusters
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>

        <!-- <p class="breadcrumb-text" style="margin-top: 2px">Clusters</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0;">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img class="cursor" @click="notificationDialog = true" src="../../assets/bell 1.svg" />
          </v-hover>
        </v-badge>

        <div>
          <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="pad px-8">
      <v-row>
        <v-col>
          <v-dialog max-width="887px" class="cdz" v-model="dialog" center>
            <v-form ref="form" lazy-validation>
              <v-card>
                <v-card-title class="gray-02 mb-8">{{ formbtn() }} Cluster</v-card-title>
                <v-card-text class="px-6 pb-0">
                  <v-text-field outlined class="rounded-xl" v-model="clusterName" label="Enter Cluster*"
                    :rules="[(v) => !!v || 'Cluster name is required']" required></v-text-field>
                  <v-text-field outlined class="rounded-xl" v-model="description" label="Description"></v-text-field>
                </v-card-text>
                <v-card-actions class="px-6 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn rounded outlined width="102px" height="48px" class="btn outline-btn pa-4" @click="() => {
                    dialog = false;
                    formbtnBool = false;
                  }
                    ">Cancel</v-btn>
                  <v-btn width="102px" height="48px" rounded @click="saveInputs" class="btn primary-btn pa-4" :loading="loading">{{
                    formbtn() }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-col>
        <!-- <v-col cols="4">
        <v-text-field label="Search" prepend-inner-icon="mdi-magnify" v-model="search" clearable></v-text-field></v-col> -->
      </v-row>

      <v-row class="d-flex flex-row justify-center my-0 py-0">
        <v-col cols="2">
          <div class="text-h6">Clusters</div>
        </v-col>

        <v-col cols="10" class="pl-0 ml-0">
          <v-row justify="end" class="mb-1 mt-0">
            <v-text-field solo label="Search here..." hide-details prepend-inner-icon="mdi-magnify" v-model="search"
              class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
            <!-- <v-btn  depressed rounded outlined class="border-button mx-3"><v-icon>mdi-tune</v-icon>FILTER</v-btn> -->
            <v-btn class="btn mx-3" outlined rounded @click="deleteDialog = true"
            :class="!selected.length == 0 ? 'outline-btn' : 'disabled-outline-btn'"
              :disabled="selected.length == 0"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
            <!-- <v-btn
            class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
            <v-btn depressed outlined rounded class="btn outline-btn mx-3" @click="downlaodRoles"><v-icon>mdi-import
                mdi-rotate-90</v-icon></v-btn>
            <v-btn v-if="$store.state.role.cluster.admin" @click="(dialog = true), newCreateClusterValue()"
              class="btn primary-btn mx-3" depressed rounded><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table fixed-header height="calc(100vh - 230px)" class="rounded-table" :search="search" v-model="selected"
        :loading="dataTableLoading" loading-text="Loading Clusters" :headers="headers" :items="tableData" show-select
        :single-select="singleSelect" :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50, 100],
        }">
        <!-- <template v-slot:[`item.created_at`]="{ item }">
        {{ getDate(item.created_at) }}
      </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="$store.state.role.cluster.admin" icon class="mr-2 pa-4" @click="updateData(item)">
            <img width="36px" height="36px" class="cursor" src="../../assets/edit.svg" />
          </v-btn> 
        </template>
          <template v-slot:no-results>
            <div class="mt-16 pt-16">
              <img 
              src="../../assets/noResultFound.svg"/>
            </div>
          </template>
        </v-data-table>
      <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-container></v-container>
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>
              <p class="text-h5 pt-6 pb-0">Delete Cluster</p>
              <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn depressed class="btn outline-btn" large width="157px" rounded
                  @click="deleteDialog = false">CANCEL</v-btn>
                <v-btn class="btn primary-btn" depressed large :loading="dLoading" width="157px" rounded
                  @click="deleteData(selected)">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Cluster Deleted</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Cluster {{ formbtnValue() }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>

  </div>
</template>

<script>
import ClusterController from "@/controllers/ClusterController";
import MastersDropdownComponent from "../../components/MastersDropdownComponent.vue"
import AuthService from "../../services/AuthService";
import Papa from "papaparse";
import Notification from "../Notification.vue";

export default {
  name: "ClustersView",
  components: {
    Notification, MastersDropdownComponent
  },
  data() {
    return {
      notificationDialog: false,
      options: {},
      pageSize: 5,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      errorMessage: "",
      dialog: false,
      dialogTitle: "Dialog Title",
      singleSelect: false,
      formbtnBool: false,
      clusterName: null,
      deleteDialog: false,
      description: null,
      dLoading: false,
      clusterData: null,
      editId: null,
      loading: false,
      selected: [],
      search: "",
      searchBool: false,
      dataTableLoading: true,
      headers: [
        { text: "Cluster Name", value: "name" },
        { text: "Description", value: "description" },
        // { text: "Created On", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
      tableData: [],
      rules: {
        required: (value) => !!value || "Field is required",
      },
    };
  },
  watch: {
    search(newValue) {
      // Update URL when search changes
      this.$router.replace({ 
        query: { 
          ...this.$route.query,
          search: newValue || undefined 
        }
      }).catch(() => {});
    }
    // options: {
    //   handler() {
    //     console.log(this.options);
    //     this.pageSize = this.options.itemsPerPage;
    //     this.page = this.options.page;
    //     if (this.searchBool) {
    //       this.searchData(this.search);
    //     } else {
    //       this.fetchAllClusters();
    //     }
    //   },
    //   deep: true,
    // },
    // search(newValue) {
    //   console.log(newValue);
    //   this.searchBool = true
    //   this.pageSize = this.options.itemsPerPage;
    //   this.page = this.options.page;
    //   this.options.page = 1;
    //   this.searchData(newValue);
    //   if (newValue == "" || newValue == null) {
    //     this.fetchAllClusters();
    //     this.searchBool = false;
    //   }
    // }
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    // async searchData(search) {
    //   const response = await ClusterController.searchCluster(
    //     search,
    //   );
    //   console.log("response", response)
    //    this.dataTableLoading = false;
    //     this.clusterData = response.data.clusters;
    //     this.tableData = this.clusterData;
    //     this.count = response.data.count;
    // },
    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },

    async downloadCSV(csvData, fileName) {

      const csv = Papa.unparse(csvData);


      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {

        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {

          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    downlaodRoles() {
      const fileName = 'clusters.csv';
      this.downloadCSV(this.clusterData, fileName);
    },
    updateData(item) {
      this.editId = item.id; // selected id for edit
      this.formbtnBool = true; // change update/create btn value
      console.log("data id", item.id);
      this.dialog = true;
      this.clusterName = item.name;
      this.description = item.description;
    },
    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    formbtnValue() {
      return this.formbtnBool === false ? "Created" : "Updated";
    },
    async deleteData(data) {
      console.log(data);
      // if (data.length == 1) {
      //   this.dLoading = true;
      //   const response = await ClusterController.deleteCluster(data[0].id);
      //   if (response.data.flag) {
      //     console.log("Delete Responce", response);
      //       this.fetchAllClusters();
      //       this.deleteDialog = false;
      //       this.dLoading = false;
      //       this.selected = [];
      // }
      // else {
      //   alert(response.data.error)
      // }
      // } else {
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id);
      });
      this.dLoading = true;
      const response = await ClusterController.deleteBulkCluster(ids);
      if (response.data.flag) {
        this.fetchAllClusters();
        this.deleteDialog = false;
        this.dLoading = false;
        this.selected = [];
      } else {
        alert(response.data.error);
      }
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },

    // },
    async newCreateClusterValue() {
      this.clusterName = null;
      this.description = null;
      this.formbtnBool = false;
    },

    async saveInputs() {
      var res;
      if (this.$refs.form.validate()) {
        this.loading = true;
        // checking case for update/create
        if (this.formbtnBool == false) {
          const response = await ClusterController.createCluster(
            this.clusterName,
            this.description
          );

          res = response;
          console.log(response);
        } else {
          const response = await ClusterController.updateCluster(
            this.clusterName,
            this.description,
            this.editId
          );
          res = response;
          console.log(response);
        }

        if (res.data.flag) {
          this.successDialog = true;
          this.fetchAllClusters();
        } else {
          this.errorDialog = true;
          this.errorMessage = res.data.error;
        }

        this.formbtnBool == false;
        this.loading = false;
        (this.clusterName = ""), (this.description = ""), (this.dialog = false);
      }
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },

    async fetchAllClusters() {
      const response = await ClusterController.getAllClustersByPagination();
      if (response.data.flag) {
        this.dataTableLoading = false;
        this.clusterData = response.data.clusters;
        this.tableData = this.sortByAlphabet(this.clusterData, "name");
        this.count = response.data.count;
      } else {
        this.dataTableLoading = false;
        // alert(response.data.error);
        alert("Something went wrong");
      }
    },
  },
  created() {
    if (!this.$store.state.role.cluster.read) {
      this.$router.push("/notFound");
    }
     // Get search from URL if it exists
     const urlSearch = this.$route.query.search;
    if (urlSearch) {
      this.search = urlSearch;
    }
    this.fetchAllClusters();
    this.$store.state.breadcrumb = "Clusters";
  },
};
</script>